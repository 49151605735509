<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Add teammate</h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      #closeMdl
      (click)="closeModal()"
    >
      <img src="assets/images/close-outline.svg" alt="close" />
    </button>
  </div>
  <div class="modal-body" [formGroup]="teamForm">
    <p>
      Invite members of your organization to start collaborating and carry out
      specific roles within the system.
    </p>
    <div class="edit-form-row package-form-row">
      <div class="form-group row align-items-center row align-items-center">
        <div class="col-3">
          <label for="teamstatus"
            >Set Status <span class="required">*</span></label
          >
        </div>
        <div class="col-9">
          <ng-select2
            class="select2-control"
            name="status"
            id="teamstatus"
            [data]="statusList"
            formControlName="status"
            [placeholder]="'Select'"
          >
          </ng-select2>
          <div
            *ngIf="f['status'].touched && f['status'].invalid"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['status'].errors && f['status'].errors['required']"
              class="required-note text-danger"
            >
              status is required.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <div class="col-3">
          <label for="role">Role <span class="required">*</span></label>
        </div>
        <div class="col-9">
          <ng-select2
            class="select2-control"
            *ngIf="rolesList.length > 0"
            name=""
            id="roleID123"
            [data]="rolesList"
            formControlName="role"
            [placeholder]="'Select'"
          >
          </ng-select2>
          <div class="form-control" *ngIf="rolesList.length === 0">
            Please config the role first!
          </div>
          <div
            *ngIf="f['role'].invalid && f['role'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['role'].errors?.['required']"
              class="required-note text-danger"
            >
              Role is required.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <div class="col-3">
          <label for="firstName">
            First Name <span class="required">*</span>
          </label>
        </div>
        <div class="col-9">
          <input
            appAlphaNumeric
            [allowSpecialCharacters]="'-'"
            type="text"
            class="form-control"
            name="firstName"
            id="firstName"
            formControlName="firstName"
            placeholder="First Name"
          />
          <div
            *ngIf="f['firstName'].touched && f['firstName'].invalid"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['firstName'].errors && f['firstName'].errors['required']"
              class="required-note text-danger"
            >
              first name is required.
            </div>
            <div
              *ngIf="f['firstName'].errors && f['firstName'].errors['pattern']"
              class="required-note text-danger"
            >
              firstName is not valid.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <div class="col-3">
          <label for="lastName">
            Last Name<span class="required">*</span>
          </label>
        </div>
        <div class="col-9">
          <input
            appAlphaNumeric
            [allowSpecialCharacters]="'-'"
            type="text"
            class="form-control"
            name="lastName"
            id="lastName"
            formControlName="lastName"
            placeholder="Last Name"
          />
          <div
            *ngIf="f['lastName'].invalid && f['lastName'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['lastName'].errors && f['lastName'].errors['required']"
              class="required-note text-danger"
            >
              last name is required.
            </div>
          </div>
          <div
            *ngIf="f['lastName'].errors && f['lastName'].errors['pattern']"
            class="required-note text-danger"
          >
            lastName is not valid.
          </div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <div class="col-3">
          <label for="email">Email <span class="required">*</span></label>
        </div>
        <div class="col-9">
          <input
            type="text"
            class="form-control"
            id="email"
            placeholder="tony@Example.com"
            formControlName="email"
          />
          <div
            *ngIf="f['email'].invalid && f['email'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['email'].errors?.['required']"
              class="required-note text-danger"
            >
              Email is required.
            </div>
            <div
              *ngIf="f['email'].errors?.['pattern']"
              class="required-note text-danger"
            >
              Valid Email is required.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <div class="col-3">
          <label for="mobileNumber"
            >Mobile Number <span class="required">*</span></label
          >
        </div>
        <div class="col-9">
          <ngx-intl-tel-input
            [cssClass]="'form-control w-100'"
            [disabled]="true"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.India"
            [phoneValidation]="true"
            [maxLength]="15"
            [numberFormat]="PhoneNumberFormat.National"
            [separateDialCode]="separateDialCode"
            name="phone"
            formControlName="mobileNumber"
          >
          </ngx-intl-tel-input>
          <div
            *ngIf="f['mobileNumber'].invalid && f['mobileNumber'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['mobileNumber'].errors && !f['mobileNumber'].errors?.['required']"
              class="form-text text-danger"
            >
              Phone number is required and must be according to the selected
              country
            </div>
            <div
              *ngIf="f['mobileNumber'].errors?.['required']"
              class="required-note text-danger"
            >
              Phone number is required.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group text-right">
        <button class="btn t-primary-btn" (click)="setTeammate()">
          <img src="assets/images/tickmark-white.svg" alt="submit" /> SUBMIT
        </button>
      </div>
    </div>
  </div>
</div>
