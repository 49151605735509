import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adv-order-details',
  templateUrl: './adv-order-details.component.html',
})
export class AdvOrderDetailsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
