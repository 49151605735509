import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';

import { IUserInfo } from '../model/common-model';

/**
 * Provides storage for authentication credential.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialService {
  userInfoUpdated: Subject<IUserInfo> = new Subject<IUserInfo>();
  userInfoUpdated$: Observable<IUserInfo> = this.userInfoUpdated.asObservable();
  private _credential: IUserInfo | null = null;

  constructor(public router: Router) {}

  /**
   * Gets the user credential.
   *
   * @return The user credential or null if the user is not authenticated.
   */
  get userDetails(): IUserInfo | null {
    return this._credential;
  }

  /**
   * Sets the user credential.
   * The credential are only persisted for the current session.
   *
   * @param credential The user credential.
   */
  setUserDetails(credential: IUserInfo) {
    this._credential = credential;
    this.userInfoUpdated.next(this._credential);
  }

  updateCredentials(credential: string, value: any) {
    const cred = this._credential;
    if (credential) {
      cred[credential] = value;
    }
    this.setUserDetails(cred);
  }
}
