import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { WalletService } from 'src/app/core/services/wallet.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-bank-account-delete-otp-verification-modal',
  templateUrl: './bank-account-delete-otp-verification-modal.component.html',
})
export class BankAccountDeleteOtpVerificationModalComponent
  extends SubscriptionDisposer
  implements OnInit, OnChanges
{
  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChild('closeModal') closeModal: any;
  @Input() mobile: any;
  @Input() bankID: any;
  @Input() payoutContinue: any;
  @Input() isRemoveAccount: any;
  @Output() isDeleteAccount = new EventEmitter();
  @Output() isPayoutOTPVerified = new EventEmitter();

  constructor(
    private storageService: StorageService,
    public generalService: GeneralService,
    private walletService: WalletService
  ) {
    super();
  }

  otp: any = '';
  counter = 60;
  resendClicked = false;
  otpForm = new UntypedFormGroup({
    digitOne: new UntypedFormControl('', [Validators.required]),
    digitTwo: new UntypedFormControl('', [Validators.required]),
    digitThree: new UntypedFormControl('', [Validators.required]),
    digitFour: new UntypedFormControl('', [Validators.required]),
  });
  otpArray = ['digitOne', 'digitTwo', 'digitThree', 'digitFour'];
  isOTPVerfied: any = false;
  isConfirm: any = false;
  bankAccountDetails: any;

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.payoutContinue) {
      this.sendPayoutOTP();
    }
    if (this.bankID) {
      this.getBankDetails();
    }
    if (this.isRemoveAccount) {
      this.sendPayoutOTP();
    }
  }

  verify(): void {
    const vendorID = this.storageService.getCookie('userID');
    if (this.otpForm.invalid) {
      this.otpForm.markAllAsTouched();
      return;
    } else {
      this.otp =
        this.otpForm.value.digitOne +
        '' +
        this.otpForm.value.digitTwo +
        '' +
        this.otpForm.value.digitThree +
        '' +
        this.otpForm.value.digitFour;
      const payload = {
        mobile_number: this.mobile,
        otp: this.otp,
        seller_id: vendorID,
      };
      this.walletService
        .verifyOTP(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res) {
              this.generalService.displaySuccess(res?.message);
              this.isOTPVerfied = true;
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  startTimer() {
    const otpTimer = setInterval(() => {
      if (this.counter > 0) {
        this.counter--;
      } else if (this.counter == 60) {
        this.resendClicked = false;
      } else {
        this.counter = 60;
        clearInterval(otpTimer);
        this.resendClicked = false;
      }
    }, 1000);
  }

  resendOTP(): void {
    if (this.resendClicked) {
      return;
    }
    if (!this.mobile) {
      this.generalService.displayWarning('Please enter valid mobile number');
      return;
    } else {
      this.resendClicked = true;
      const vendorID = this.storageService.getCookie('userID');
      if (this.payoutContinue) {
        this.walletService
          .sendPayoutOTP({ mobile: this.mobile, seller_id: vendorID })
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (response) => {
              if (response) {
                this.generalService.displaySuccess(response?.message);
                this.startTimer();
              }
            },
            (error) => {
              this.resendClicked = false;
              this.generalService.displayError(error?.error?.message);
            }
          );
      } else {
        this.walletService
          .sendOTPForDeleteAccountVerification({
            mobile: this.mobile,
            seller_id: vendorID,
          })
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (response) => {
              if (response) {
                this.generalService.displaySuccess(response?.message);
                this.counter = 60;
                this.startTimer();
              }
            },
            (error) => {
              this.resendClicked = false;
              this.generalService.displayError(error?.error?.message);
            }
          );
      }
    }
  }

  sendPayoutOTP(): void {
    if (!this.mobile) {
      this.generalService.displayWarning('Please enter valid mobile number');
      return;
    } else {
      this.resendClicked = true;
      const vendorID = this.storageService.getCookie('userID');
      if (this.payoutContinue) {
        this.walletService
          .sendPayoutOTP({ mobile_number: this.mobile, seller_id: vendorID })
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (response) => {
              if (response) {
                this.generalService.displaySuccess(response?.message);
                this.startTimer();
              }
            },
            (error) => {
              this.resendClicked = false;
              this.generalService.displayError(error?.error?.message);
            }
          );
      } else {
        this.walletService
          .sendOTPForDeleteAccountVerification({
            mobile: this.mobile,
            seller_id: vendorID,
          })
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (response) => {
              if (response) {
                this.generalService.displaySuccess(response?.message);
                this.counter = 60;
                this.startTimer();
              }
            },
            (error) => {
              this.resendClicked = false;
              this.generalService.displayError(error?.error?.message);
            }
          );
      }
    }
  }

  get f() {
    return this.otpForm.controls;
  }

  handleInput(ele: number): void {
    if (this.otpForm.controls[this.otpArray[ele - 1]].valid) {
      setTimeout(() => {
        this.otpInputs.get(ele)?.nativeElement.focus();
      });
    }
  }

  deleteAccount(): void {
    if (!this.isConfirm) {
      this.generalService.displayWarning('Please select confirm box!');
      return;
    } else {
      this.resendClicked = true;
      this.walletService
        .removeAccount({ account_id: this.bankID })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response) {
              this.generalService.displaySuccess(response?.message);
              this.closeModal?.nativeElement?.click();
              this.isDeleteAccount?.emit(true);
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  verifyOTP(): void {
    const vendorID = this.storageService.getCookie('userID');
    if (this.otpForm.invalid) {
      this.otpForm.markAllAsTouched();
      return;
    } else {
      this.otp =
        this.otpForm.value.digitOne +
        '' +
        this.otpForm.value.digitTwo +
        '' +
        this.otpForm.value.digitThree +
        '' +
        this.otpForm.value.digitFour;
      const payload = {
        mobile_number: this.mobile,
        otp: this.otp,
        seller_id: vendorID,
      };
      this.walletService
        .payoutOTPVerification(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res) {
              this.generalService.displaySuccess(res?.message);
              this.isPayoutOTPVerified.emit(res?.data?.reference_number);
              this.closeModal?.nativeElement?.click();
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  reset(): void {
    this.otpForm.reset();
    this.isOTPVerfied = false;
    this.counter = 60;
  }

  getBankDetails(): void {
    this.walletService
      .getBankAccountDetails(this.bankID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          if (response) {
            this.bankAccountDetails = response?.data;
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }
}
