import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-assign-variant-image-modal',
  templateUrl: './assign-variant-image-modal.component.html',
})
export class AssignVariantImageModalComponent implements OnInit {
  existingImages: any;
  selectedProductImages: any;
  optionValueID: any;
  isAddonImg: any;
  assignImgPackageIndex: any;
  selectAssignedImgUrl: any;
  isVariantImg: any;

  assignedImgUrl: any;

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      existingImages: any;
      selectedProductImages: any;
      optionValueID: any;
      isAddonImg: any;
      assignImgPackageIndex: any;
      selectAssignedImgUrl: any;
      isVariantImg: any;
      isReview: boolean;
    }
  ) {
    if (data.existingImages) {
      this.existingImages = data.existingImages;
    }
    if (data.selectedProductImages) {
      this.selectedProductImages = data.selectedProductImages;
    }
    if (data.optionValueID) {
      this.optionValueID = data.optionValueID;
    }
    if (data.isAddonImg) {
      this.isAddonImg = data.isAddonImg;
    }
    if (data.assignImgPackageIndex) {
      this.assignImgPackageIndex = data.assignImgPackageIndex;
    }
    if (data.selectAssignedImgUrl) {
      this.selectAssignedImgUrl = data.selectAssignedImgUrl;
    }
    if (data.isVariantImg) {
      this.isVariantImg = data.isVariantImg;
    }
  }

  ngOnInit(): void {
    this.existingImages?.map((obj: any) => {
      const imgArr = obj?.img_url?.split('/');
      obj.file_name = imgArr[imgArr?.length - 1];
      if (this.selectAssignedImgUrl == obj.img_url) {
        obj.isSelected = true;
      } else {
        obj.isSelected = false;
      }
    });
    this.selectedProductImages?.map((obj: any) => {
      if (this.selectAssignedImgUrl == obj.img_url) {
        obj.isSelected = true;
      } else {
        obj.isSelected = false;
      }
    });
  }

  selectImage(type: any, img_url: any, index: any): void {
    if (type == 1) {
      this.existingImages.map((item: any, key: any) => {
        if (img_url == item?.img_url) {
          item.isSelected = true;
        } else {
          item.isSelected = false;
        }
      });
      this.selectedProductImages.map((item: any, key: any) => {
        item.isSelected = false;
      });
    } else {
      this.selectedProductImages.map((item: any, key: any) => {
        if (img_url == item?.img_url) {
          item.isSelected = true;
        } else {
          item.isSelected = false;
        }
      });
      this.existingImages.map((item: any, key: any) => {
        item.isSelected = false;
      });
    }
  }

  save(): void {
    this.assignedImgUrl = '';
    const selectedExtImg = this.existingImages?.filter(
      (obj: any) => obj.isSelected == true
    );
    const selectedNewImg = this.selectedProductImages?.filter(
      (obj: any) => obj.isSelected == true
    );

    if (selectedExtImg?.[0]) {
      this.assignedImgUrl = selectedExtImg[0]?.img_url;
    } else {
      this.assignedImgUrl = selectedNewImg[0]?.img_url;
    }
    const imgObj = {
      img_url: this.assignedImgUrl,
      optionValueID: this.optionValueID,
      isAddonImg: this.isAddonImg,
      new_img_id: selectedNewImg[0]?.new_img_id,
      isVariantImg: this.isVariantImg,
      assignImgPackageIndex: this.assignImgPackageIndex,
    };
    this.close(imgObj);
  }

  reset(): void {
    this.existingImages.map((item: any, key: any) => {
      item.isSelected = false;
    });
    this.selectedProductImages.map((item: any, key: any) => {
      item.isSelected = false;
    });
  }

  close(data?: any) {
    console.log('image add close modal data return', data);
    this.dialogRef.close(data);
  }
}
