<div class="modal-header">
  <h4 class="modal-title">Report Template Created</h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    #closeMdl
    aria-label="Close"
    (click)="closeModal()"
  >
    <img src="assets/images/close-outline.svg" alt="close" />
  </button>
</div>
<div class="modal-body">
  <div class="report-create-template">
    <div class="edit-form-row">
      <div class="form-group">
        <div class="left-title">
          <h6>Template Name</h6>
        </div>
        <div class="right-field" [formGroup]="fildForm">
          <ng-select2
            name="fildType"
            class="select2-control"
            [placeholder]="'Select Type'"
            [data]="reportNameList"
            formControlName="fildType"
            (ngModelChange)="selectTempField($event)"
          >
          </ng-select2>
          <div
            *ngIf="f['fildType'].invalid && f['fildType'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['fildType'].errors && f['fildType'].errors['required']"
              class="required-note text-danger"
            >
              Entity type is required.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="left-title">
          <h6>Field should Include</h6>
        </div>
        <div class="right-field">
          <div class="search-control-input">
            <input
              class="form-control"
              type="text"
              placeholder=""
              [(ngModel)]="searchKey"
            />
            <a href="javascript: void(0)" class="btn search-btn">
              <img
                src="assets/images/search-icon-white.svg"
                alt="search"
                (click)="search()"
              />
            </a>
          </div>
          <div class="selected-items-tag">
            <ul>
              <ng-container
                *ngFor="let item of reportTemplatelList; let i = index"
              >
                <li *ngIf="item.is_selected">
                  {{ item.template_field }}
                  <a href="javascript: void()" (click)="removeFild(i)"
                    ><img src="assets/images/close-grey.svg" alt="trash"
                  /></a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="template-checkbox-group">
      <ul>
        <li *ngFor="let item of reportTemplatelList; let i = index">
          <div class="c-checkbox">
            <input
              type="checkbox"
              name="type"
              id="check{{ i }}"
              (change)="isChecked($event)"
              [(ngModel)]="item.is_selected"
            />
            <label for="check{{ i }}">{{ item.template_field }}</label>
          </div>
        </li>
      </ul>
      <div *ngIf="reportTemplatelList.length == 0" class="empty-record-td">
        <app-no-records></app-no-records>
      </div>
    </div>
    <div class="bottom-btnrow mt-5">
      <button class="btn t-grey-btn-outline" (click)="reset()">
        <img src="assets/images/reset-icon.svg" alt="reset" /> RESET
      </button>
      <button class="btn t-primary-btn" (click)="save()">
        <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
      </button>
    </div>
  </div>
</div>
