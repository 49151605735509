<footer>
  <div class="footer-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="footer-widget-box">
            <h4 class="footer-cat-title">Categories</h4>

            <ul>
              <li *ngFor="let item of categoryList">
                <a
                  [href]="redirectedURL"
                  target="_blank"
                  (click)="gotoCategory(item?._id)"
                >
                  {{ item?.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="footer-widget-box">
            <h4 class="footer-cat-title" [routerLink]="['/about-us']">About</h4>

            <ul>
              <li>
                <a href="javascript:void(0)" [routerLink]="['/contact-us']">
                  Careers
                </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Press & News </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Partnerships </a>
              </li>
              <li>
                <a href="javascript:void(0)" [routerLink]="['/privacy-policy']">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  [routerLink]="['/terms-conditions']"
                >
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Intellectual Property Claims </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Investor Relations </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="footer-widget-box">
            <h4 class="footer-cat-title">Support</h4>

            <ul>
              <li>
                <a href="javascript:void(0)"> Help & Support </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Trust & Safety </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Selling on Meydan </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Buying on Meydan </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="footer-widget-box">
            <h4 class="footer-cat-title">More From Meydan</h4>

            <ul>
              <li>
                <a href="javascript:void(0)"> Events </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Blog </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Forum </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Community Standards </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Podcast </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Affiliates </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Invite a Friend </a>
              </li>
              <li>
                <a href="javascript:void(0)"> Become a Seller </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div class="copyright-content">
            <div class="foot-logo">
              <img
                [src]="
                  this.generalService.generalConfigData.logo
                    ? this.generalService.generalConfigData.logo
                    : 'assets/images/meydan-logo.svg'
                "
                alt="site-logo"
              />
            </div>
            <p>Meydan Service Marketplace. © {{ currentYear }}</p>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="social-media-links">
            <ul>
              <li>
                <a href="javascript:void(0)">
                  <img src="assets/images/facebook.svg" alt="fb" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <img src="assets/images/instagram.svg" alt="ig" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <img src="assets/images/twitter.svg" alt="tw" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <img src="assets/images/linkedin.svg" alt="lin" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
