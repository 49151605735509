import { Component, OnInit } from '@angular/core';

import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { VendorService } from 'src/app/core/services/vendor.service';

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
})
export class AllNotificationsComponent implements OnInit {
  vendorID = this.storageService.getCookie('userID');
  isAll = false;
  filterID = 0;
  notificationsLists: any = [];
  allCount: any = 0;
  unreadCount: any = 0;
  requestCount: any = 0;
  invoiceNotifications: any = [];

  constructor(
    private storageService: StorageService,
    private vendorService: VendorService,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.getNotifications();
    this.getInvoiceNotifications();
  }

  getNotifications(): void {
    const payload = {
      filter: this.filterID,
    };
    this.vendorService
      .getAllNotifications(
        this.vendorID,
        this.generalService.createParamsURL(payload)
      )
      .subscribe(
        (res) => {
          this.notificationsLists = res.data;
          this.allCount = res?.all_count;
          this.unreadCount = res?.unread_count;
        },
        (err) => {
          this.generalService.displayError(err.error.message);
        }
      );
  }

  getInvoiceNotifications(): void {
    this.vendorService.getInvoiceNotifications(this.vendorID).subscribe(
      (res) => {
        const invoiceNotifications =
          res?.data?.done?.length > 0 ? res?.data?.done : [];
        const invoiceNotificationsTodos =
          res?.data?.todo?.length > 0 ? res?.data?.todo : [];
        this.invoiceNotifications = [
          ...invoiceNotifications,
          ...invoiceNotificationsTodos,
        ];
        this.requestCount = invoiceNotificationsTodos?.length;
      },
      (err) => {
        this.generalService.displayError(err.error.message);
      }
    );
  }

  getAllNotifications(): void {
    this.isAll = true;
    this.getNotifications();
  }

  markAllAsRead(): void {
    const payload = {
      is_all: true,
      seller_id: this.vendorID,
    };
    this.vendorService.markAsReadNotification(payload).subscribe(
      (res) => {
        this.getInvoiceNotifications();
        this.getNotifications();
      },
      (err) => {
        this.generalService.displayError(err.error.message);
      }
    );
  }

  filterApply(type: any): void {
    this.filterID = type;
    this.getNotifications();
  }

  markAsReadNotification(id: any): void {
    const payload = {
      notification_id: id,
      is_all: false,
      seller_id: this.vendorID,
    };
    this.vendorService.markAsReadNotification(payload).subscribe(
      (res) => {
        this.getInvoiceNotifications();
        this.getNotifications();
      },
      (err) => {
        this.generalService.displayError(err.error.message);
      }
    );
  }
}
