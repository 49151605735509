import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { OrderManagementService } from 'src/app/core/services/order-management.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-invoice-print',
  templateUrl: './invoice-print.component.html',
})
export class InvoicePrintComponent
  extends SubscriptionDisposer
  implements OnInit
{
  // @Input() invoiceDetails: any;
  @Input() isVATInvoice: any = true;
  @Input() isPrint: any;

  billingAddress: any;
  shippingAddress: any;
  finalSubtotal: any = 0;
  finalGrandTotal: any = 0;
  finalDiscount: any = 0;
  finalTax: any = 0;
  invoiceID: any;
  invoiceDetails: any;

  constructor(
    public generalService: GeneralService,
    private router: Router,
    private orderMngtService: OrderManagementService
  ) {
    super();
  }

  ngOnInit(): void {
    const pageUrl = this.router.url;
    if (pageUrl) {
      const urlArr = pageUrl?.split('/');
      if (urlArr?.length > 0) {
        if (this.isPrint) {
          const url = urlArr[urlArr?.length - 1];
          const lastPath = url?.split('=');
          this.invoiceID = lastPath?.length
            ? lastPath[lastPath?.length - 1]
            : '';
        } else {
          this.invoiceID = urlArr[urlArr?.length - 1];
        }
        if (this.invoiceID) {
          this.getInvoicePrintDetails();
        }
      }
    }
  }

  // ngOnChanges(): void {
  //   if (this.invoiceDetails) {
  //     this.billingAddress = this.invoiceDetails?.billing_address;
  //     if (this.invoiceDetails?.product.length > 0) {
  //       this.shippingAddress = this.invoiceDetails?.product[0]?.selected_address
  //     }
  //     if (this.invoiceDetails?.type == 1) {
  //       this.invoiceDetails?.product.map((item: any) => {
  //         if (item.variants?.price) {
  //           item.total_excluding_vat = Number(((item.variants?.sale_price && item?.variants?.sale_price != '') ? item?.variants?.sale_price : (item?.variants?.price)) * item.product_quantity)
  //           item.total_including_vat = Number(item.tax_price) + Number(item.total_excluding_vat);
  //         }
  //         else {
  //           item.total_excluding_vat = Number(item?.product_price * item.product_quantity)
  //           item.total_including_vat = Number(item.tax_price) + Number(item.total_excluding_vat);
  //         }
  //       });
  //       this.calculateTotal();
  //     }
  //     else {
  //       this.invoiceDetails.service['vat'] = 5;
  //       this.invoiceDetails.service['total_including_vat'] = Number(this.invoiceDetails?.service?.tax) + Number(this.invoiceDetails?.service?.price);
  //       this.calculateTotal();
  //     }
  //   }
  // }

  getInvoicePrintDetails() {
    this.orderMngtService
      .viewInvoiceDetails({ invoiceID: this.invoiceID })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response: any) => {
          if (response && response.data) {
            this.invoiceDetails = response.data;
            this.billingAddress = this.invoiceDetails?.billing_address;
            if (this.invoiceDetails?.product.length > 0) {
              this.shippingAddress =
                this.invoiceDetails?.product[0]?.selected_address;
            }
            if (this.invoiceDetails?.type == 1) {
              this.invoiceDetails?.product.map((item: any) => {
                if (item.variants?.price) {
                  item.total_excluding_vat = Number(
                    (item.variants?.sale_price &&
                    item?.variants?.sale_price != ''
                      ? item?.variants?.sale_price
                      : item?.variants?.price) * item.product_quantity
                  );
                  item.total_including_vat =
                    Number(item.tax_price) + Number(item.total_excluding_vat);
                } else {
                  item.total_excluding_vat = Number(
                    item?.product_price * item.product_quantity
                  );
                  item.total_including_vat =
                    Number(item.tax_price) + Number(item.total_excluding_vat);
                }
              });
              this.calculateTotal();
            } else {
              this.invoiceDetails.service['vat'] = 5;
              this.invoiceDetails.service['total_including_vat'] =
                Number(this.invoiceDetails?.service?.tax) +
                Number(this.invoiceDetails?.service?.price);
              this.calculateTotal();
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  calculateTotal(): void {
    this.finalSubtotal = this.invoiceDetails?.subtotal
      ? this.invoiceDetails?.subtotal
      : 0;
    this.finalDiscount = this.invoiceDetails?.discount
      ? this.invoiceDetails?.discount
      : 0;
    this.finalTax = this.invoiceDetails?.tax ? this.invoiceDetails?.tax : 0;
    if (this.isVATInvoice) {
      this.finalGrandTotal = Number(this.invoiceDetails?.total)
        ? Number(this.invoiceDetails?.total)
        : 0;
    } else {
      this.finalGrandTotal =
        Number(this.invoiceDetails?.total) - Number(this.finalTax);
      this.finalGrandTotal = this.finalGrandTotal ? this.finalGrandTotal : 0;
    }
  }
}
