<div
  class="custom-dialog-modal modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Add a Bank Account</h4>
      <button type="button" class="close" (click)="closeModal()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body" [formGroup]="bankForm">
      <div class="add-acc-form">
        <div class="edit-form-row">
          <div class="form-group">
            <div class="left-title">
              <label>Name<span class="required">*</span></label>
            </div>
            <div class="right-field">
              <input type="text" class="form-control" formControlName="name" />
              <div
                *ngIf="f['name'].invalid && f['name'].touched"
                class="cross-validation-error-message"
              >
                <div
                  *ngIf="f['name'].errors && f['name'].errors['required']"
                  class="form-text text-danger"
                >
                  Name is required
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Bank Name<span class="required">*</span></label>
            </div>
            <div class="right-field">
              <ng-select2
                *ngIf="bankNameList.length > 0"
                class="select2-control"
                name=""
                id="bankNameListID"
                [data]="bankNameList"
                placeholder="Select"
                formControlName="bank"
              >
              </ng-select2>
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Account Number<span class="required">*</span></label>
            </div>
            <div class="right-field">
              <input
                type="text"
                class="form-control"
                (keypress)="generalService.numberOnly($event)"
                maxlength="20"
                formControlName="accountNumber"
              />
              <div
                *ngIf="f['accountNumber'].invalid && f['accountNumber'].touched"
                class="cross-validation-error-message"
              >
                <div
                  *ngIf="
                    f['accountNumber'].errors &&
                    f['accountNumber'].errors['required']
                  "
                  class="form-text text-danger"
                >
                  Account Number is required
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>IBAN<span class="required">*</span></label>
            </div>
            <div class="right-field">
              <input type="text" class="form-control" formControlName="iban" />
              <div
                *ngIf="f['iban'].invalid && f['iban'].touched"
                class="cross-validation-error-message"
              >
                <div
                  *ngIf="f['iban'].errors && f['iban'].errors['required']"
                  class="form-text text-danger"
                >
                  IBAN is required
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Swift Code</label>
            </div>
            <div class="right-field">
              <input
                type="text"
                class="form-control"
                placeholder="(Optional)"
                formControlName="swiftCode"
              />
            </div>
          </div>
          <div class="c-checkbox">
            <input
              type="checkbox"
              name=""
              id="checkBox111"
              formControlName="setAsDefault"
            />
            <label for="checkBox111"> Set as default </label>
          </div>
          <div class="bottom-btnrow">
            <button class="btn t-primary-btn" (click)="save()">
              <img src="assets/images/save-check.svg" alt="save" /> Save account
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
