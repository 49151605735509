<div class="modal-body">
  <div class="modal-title">
    <h4>Change Password</h4>
  </div>
  <div class="change-email-content">
    <form name="passwordForm" [formGroup]="passwordForm">
      <div class="form-group">
        <div class="password-group">
          <label for=""
            >Enter Current password<span class="required">*</span>
          </label>
          <input
            [type]="showCurrentPwd ? 'text' : 'password'"
            class="form-control"
            id="current-pwd"
            formControlName="oldPassword"
          />
          <span
            class="show-pwd"
            id="show-hide-pwd"
            (click)="showCurrentPwd = !showCurrentPwd"
            ><img
              [src]="
                showCurrentPwd
                  ? 'assets/images/show-hide-icon.svg'
                  : 'assets/images/show-eye-icon.svg'
              "
              alt="pwd-icon"
          /></span>
        </div>
        <div
          *ngIf="f['oldPassword'].invalid && f['oldPassword'].touched"
          class="cross-validation-error-message"
        >
          <div
            *ngIf="
              f['oldPassword'].errors && f['oldPassword'].errors['required']
            "
            class="form-text text-danger"
          >
            Current Password is required.
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="password-group">
          <label for=""
            >Enter New password<span class="required">*</span></label
          >
          <input
            [type]="showNewPwd ? 'text' : 'password'"
            class="form-control"
            id="new-pwd"
            formControlName="password"
            (input)="validatePwd()"
          />
          <span
            class="show-pwd"
            id="show-hide-pwd"
            (click)="showNewPwd = !showNewPwd"
            ><img
              [src]="
                showNewPwd
                  ? 'assets/images/show-hide-icon.svg'
                  : 'assets/images/show-eye-icon.svg'
              "
              alt="pwd-icon"
          /></span>
        </div>
        <div
          *ngIf="f['password'].invalid && f['password'].touched"
          class="cross-validation-error-message"
        >
          <div
            *ngIf="f['password'].errors && f['password'].errors['required']"
            class="form-text text-danger"
          >
            New Password is required.
          </div>
        </div>
        <div
          *ngIf="passwordForm.value.rePassword && !passMatch"
          class="form-text text-danger"
        >
          Password does not match with re-password.
        </div>
      </div>
      <div class="password-checklist">
        <h6>Password must:</h6>
        <ul>
          <li
            [ngClass]="{
              checked: passStrength?.passStrength?.contains.includes('number')
            }"
          >
            Contain at least one numeric character
          </li>
          <li
            [ngClass]="{
              checked:
                passStrength?.passStrength?.contains.includes('uppercase')
            }"
          >
            Contain at least one uppercase letter
          </li>
          <li
            [ngClass]="{
              checked:
                passStrength?.passStrength?.contains.includes('lowercase')
            }"
          >
            Contain at least one lowercase letter
          </li>
          <li [ngClass]="{ checked: passStrength?.isContainSpace }">
            Not contain any spaces
          </li>
        </ul>
      </div>
      <div class="form-group">
        <div class="password-group">
          <label for=""
            >Re-enter new password<span class="required">*</span>
          </label>
          <input
            type="password"
            [type]="showRetypePwd ? 'text' : 'password'"
            class="form-control"
            id="re-pwd"
            formControlName="rePassword"
            (input)="comparePassword()"
          />
          <span
            class="show-pwd"
            id="show-hide-pwd"
            (click)="showRetypePwd = !showRetypePwd"
            ><img
              [src]="
                showRetypePwd
                  ? 'assets/images/show-hide-icon.svg'
                  : 'assets/images/show-eye-icon.svg'
              "
              alt="pwd-icon"
          /></span>
        </div>
        <div
          *ngIf="f['rePassword'].invalid && f['rePassword'].touched"
          class="cross-validation-error-message"
        >
          <div
            *ngIf="f['rePassword'].errors && f['rePassword'].errors['required']"
            class="form-text text-danger"
          >
            Re-Password is required
          </div>
        </div>
        <div
          *ngIf="passwordForm.value.rePassword && !passMatch"
          class="form-text text-danger"
        >
          Re-Password does not match with password.
        </div>
      </div>
    </form>
  </div>
  <div class="filter-actions-bottom card-box-action">
    <ul>
      <li>
        <a href="javascript: void(0)" data-dismiss="modal" #closeModal>
          Cancel
        </a>
      </li>
      <li class="last-btn">
        <a href="javascript: void(0)" class="btn t-blue-btn" (click)="save()">
          Submit
        </a>
      </li>
    </ul>
  </div>
</div>
