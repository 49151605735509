<div class="modal-header">
  <h4 class="modal-title">Bank Account Details</h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    #closeModal
    aria-label="Close"
  >
    <img src="assets/images/close-outline.svg" alt="close" />
  </button>
</div>
<div class="modal-body">
  <div class="request-payout-form">
    <div class="bank-acc-details">
      <div class="beneficiary-account-details">
        <div class="account-info">
          <ul>
            <li>
              <div class="left-label">Account Number</div>
              <div class="right-label">
                {{ bankAccountDetails?.account_number }}
              </div>
            </li>
            <li>
              <div class="left-label">IBAN</div>
              <div class="right-label">{{ bankAccountDetails?.iban }}</div>
            </li>
            <li>
              <div class="left-label">Account Holder Name</div>
              <div class="right-label">{{ bankAccountDetails?.name }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom-btnrow">
      <button class="btn t-danger-btn-outline" (click)="removeCard()">
        <img src="assets/images/metro-bin.svg" alt="trash" class="icon" />
        REMOVE CARD
      </button>
    </div>
  </div>
</div>
