import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Select2OptionData } from 'ng-select2';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { takeUntil } from 'rxjs';
import { AccountSettingsService } from 'src/app/core/services/account-settings.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-add-teammates-modal',
  templateUrl: './add-teammates-modal.component.html',
})
export class AddTeammatesModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  @ViewChild('closeMdl') closeMdl: any;
  @Output('updateTeamsList') updateList = new EventEmitter();

  rolesList: Array<Select2OptionData> = [];
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.UnitedArabEmirates,
    CountryISO.UnitedKingdom,
  ];
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  separateDialCode = false;

  constructor(
    private accountSettingsService: AccountSettingsService,
    private generalService: GeneralService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getRolesList();
  }

  statusList: Array<Select2OptionData> = [
    { id: '1', text: 'Enabled' },
    { id: '0', text: 'Disabled' },
  ];

  teamForm = new UntypedFormGroup({
    status: new UntypedFormControl('1', [Validators.required]),
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(
        /^([A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9]))$/
      ),
    ]),
    role: new UntypedFormControl('', [Validators.required]),
    firstName: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^\S(?:\s*\S)*$/),
    ]),
    lastName: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^\S(?:\s*\S)*$/),
    ]),
    mobileNumber: new UntypedFormControl({ value: '' }, [Validators.required]),
  });

  getRolesList(): void {
    const vendorID = this.storageService.getCookie('userID');
    this.accountSettingsService
      .getRolesList(vendorID, '')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          if (res) {
            const roles = res?.data;
            roles.filter((item: any) => {
              this.rolesList.push({
                id: item._id,
                text: item.role_name,
              });
            });
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  setTeammate(): void {
    if (this.teamForm.invalid) {
      this.teamForm.markAllAsTouched();
      return;
    } else {
      const payload = {
        status: this.teamForm.value.status,
        email: this.teamForm.value.email,
        role: this.teamForm.value.role,
        seller: this.storageService.getCookie('userID'),
        first_name: this.teamForm.value.firstName,
        last_name: this.teamForm.value.lastName,
        mobile_number: this.teamForm.value.mobileNumber,
      };
      this.accountSettingsService
        .setTeammate(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (res) => {
            if (res?.success) {
              this.generalService.displaySuccess(res?.message);
              this.updateList.emit();
              this.closeModal();
            } else {
              this.generalService.displayError(res?.message);
            }
          },
          error: (error) => {
            this.generalService.displayError(error?.error?.message);
          },
        });
    }
  }

  get f() {
    return this.teamForm.controls;
  }

  closeModal(): void {
    this.closeMdl.nativeElement.click();
    this.teamForm.reset();
  }
}
