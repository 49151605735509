import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { takeUntil } from 'rxjs';
import { AccountSettingsService } from 'src/app/core/services/account-settings.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-password-configuration-modal',
  templateUrl: './password-configuration-modal.component.html',
})
export class PasswordConfigurationModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  @ViewChild('closeModal') closeMdl: any;
  @Output() closePwdMdl = new EventEmitter();
  @Input() password: any;

  passwordForm = new UntypedFormGroup({
    oldPassword: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
    rePassword: new UntypedFormControl('', [Validators.required]),
  });

  passStrength: any;
  isPwdValid = false;
  showRetypePwd: any = false;
  showNewPwd: any = false;
  showCurrentPwd: any = false;
  passMatch: any = false;

  constructor(
    private accountSettingService: AccountSettingsService,
    private generalService: GeneralService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit(): void {}

  comparePassword() {
    const { password, rePassword } = this.passwordForm.getRawValue();
    if (password !== rePassword) this.passMatch = false;
    else this.passMatch = true;
  }

  save(): void {
    if (this.passwordForm.invalid) {
      this.passwordForm.markAllAsTouched();
      return;
    } else {
      if (this.passMatch && this.isPwdValid) {
        this.accountSettingService
          .editPassword({
            oldpassword: this.passwordForm.value.oldPassword,
            password: this.passwordForm.value.password,
            seller_id: this.storageService.getCookie('userID'),
          })
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (response) => {
              if (response) {
                this.generalService.displaySuccess(response.message);
                this.closePwdMdl.emit();
                this.closeModal();
              }
            },
            (error) => {
              this.generalService.displayError(error?.error?.message);
            }
          );
      } else {
        if (
          this.passwordForm.value.password != this.passwordForm.value.rePassword
        ) {
          this.generalService.displayError('Password Mismatched');
        } else {
          this.generalService.displayWarning('Please enter vaild password.');
        }
      }
    }
  }

  get f() {
    return this.passwordForm.controls;
  }

  validatePwd(): void {
    const { password } = this.passwordForm.getRawValue();
    this.passStrength = this.generalService.validatePassword(password);
    const { isContainSpace, contains } = this.passStrength?.passStrength;
    if (
      !isContainSpace &&
      contains?.includes('lowercase') &&
      contains?.includes('number') &&
      contains?.includes('uppercase')
    ) {
      this.isPwdValid = true;
    } else {
      this.isPwdValid = false;
    }
  }

  closeModal(): void {
    this.closeMdl.nativeElement.click();
    this.passwordForm.reset();
  }
}
