import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-add-package-custom-setting-modal',
  templateUrl: './add-package-custom-setting-modal.component.html',
})
export class AddPackageCustomSettingModalComponent
  implements OnInit, OnChanges
{
  @ViewChild('closeModal') closeMdl: any;
  @Output() showColumns = new EventEmitter();
  @Input() packageID: any;

  showList: any = [
    {
      id: 1,
      name: 'Image',
      isShow: true,
    },
    {
      id: 2,
      name: 'Name',
      isShow: true,
    },
    {
      id: 3,
      name: 'Price',
      isShow: true,
    },
    {
      id: 7,
      name: 'Sale Price',
      isShow: true,
    },
  ];

  hideList: any = [
    {
      id: 5,
      name: 'Available Qty',
      isShow: false,
    },
    {
      id: 6,
      name: 'SKU',
      isShow: false,
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.reset();
  }

  ngOnChanges(): void {
    this.reset();
  }

  hideShow(obj: any, type: any, index: any): void {
    if (type == 1) {
      this.showList.splice(index, 1);
      this.hideList.push({
        id: obj.id,
        name: obj.name,
        isShow: false,
      });
    } else {
      this.hideList.splice(index, 1);
      this.showList.push({
        id: obj.id,
        name: obj.name,
        isShow: true,
      });
    }
  }

  reset(): void {
    this.showList = [];
    this.hideList = [];
    this.showList.push(
      {
        id: 1,
        name: 'Image',
        isShow: true,
      },
      {
        id: 2,
        name: 'Name',
        isShow: true,
      },
      {
        id: 3,
        name: 'Price',
        isShow: true,
      },
      {
        id: 7,
        name: 'Sale Price',
        isShow: true,
      }
    );
    this.hideList.push(
      {
        id: 5,
        name: 'Available Qty',
        isShow: false,
      },
      {
        id: 6,
        name: 'SKU',
        isShow: false,
      }
    );
  }

  save(): void {
    this.closeVariantModal();
    const obj = {
      packageID: this.packageID,
      columns: this.showList,
    };
    this.showColumns.emit(obj);
  }

  closeVariantModal(): void {
    this.closeMdl?.nativeElement?.click();
  }
}
