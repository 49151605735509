import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as HttpStatus from 'http-status-codes';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { HttpErrorHandler } from 'src/app/core/services/http-error.handler';
import { environment } from 'src/environments/environment';

import { GeneralService } from '../services/general.service';
import { StorageService } from '../services/storage.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private storageService: StorageService,
    private generalService: GeneralService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private httpErrorHandler: HttpErrorHandler
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.spinner.show();
    return next
      .handle(
        this.addToken(
          request,
          this.storageService.getCookieExpiration('AccessToken')
        )
      )
      .pipe(
        finalize(() => this.spinner.hide()),
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide();
          if (error.error instanceof ErrorEvent) {
            this.generalService.displayError(`Error: ${error.error.message}`);
          } else {
            switch (error.status) {
              case HttpStatus.StatusCodes.UNAUTHORIZED:
                this.generalService.displayError(`${error.error.error}`);
                this.logout();
                break;
              case HttpStatus.StatusCodes.FORBIDDEN:
                this.logout();
                break;
              default:
                if (this.shouldHandleHttpError(request)) {
                  this.handleHttpError(error);
                }
            }
          }
          return throwError(error);
        })
      );
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (req.url.indexOf(environment.baseApiUrl) == -1) {
      return req;
    }
    // else if (this.isAccessToken(req.url)) {
    //     return req;
    // }
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }

  private handleHttpError(err: HttpErrorResponse): void {
    this.httpErrorHandler.handle(err);
  }

  private logout(): void {
    this.generalService.logout();
  }

  // tslint:disable-next-line: typedef
  private shouldHandleHttpError(request: HttpRequest<any>) {
    return !request.headers.has('_skiphandleerror');
  }
}
