import { Injectable } from '@angular/core';

import { AccountSettingsService } from '../services/account-settings.service';
import { StorageService } from '../services/storage.service';
import { VendorService } from '../services/vendor.service';

@Injectable({
  providedIn: 'root',
})
export class UserIdentityInitializer {
  constructor(
    private localStorage: StorageService,
    private vendorService: VendorService,
    private accountSettingsService: AccountSettingsService
  ) {
    this.Init();
  }

  async Init(): Promise<boolean> {
    const payload = {
      sellerId: this.localStorage.getCookie('userID'),
    };
    if (this.localStorage.getCookie(`AccessToken`)) {
      return new Promise((resolve) => {
        this.accountSettingsService
          .getUserIdentityDetails(payload)
          .subscribe((response: any) => {
            if (response && response.success) {
              this.vendorService.setUserDetail(response?.data);
              resolve(true);
            } else {
              resolve(true);
            }
          });
      });
    } else {
      return Promise.resolve(true);
    }
  }
}
