import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

import { Select2OptionData } from 'ng-select2';
import { takeUntil } from 'rxjs';
import { CatalogManagementService } from 'src/app/core/services/catalog-management.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';
import { AirDatepickerOptions, AirDatepickerPosition } from 'air-datepicker';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-crosssell-upsell-tag-selection-modal',
  templateUrl: './crosssell-upsell-tag-selection-modal.component.html',
})
export class CrosssellUpsellTagSelectionModalComponent
  extends SubscriptionDisposer
  implements OnInit, OnChanges
{
  specificTag: any;
  isEditTag: any;
  isService: any;

  categories: Array<Select2OptionData> = [];
  subCategories: Array<Select2OptionData> = [];
  selectedTag: any;
  minDate: any;

  tagForm = new FormGroup({
    categoryID: new UntypedFormControl('', [Validators.required]),
    subCategoryID: new UntypedFormControl(''),
    startDate: new UntypedFormControl('', [Validators.required]),
    startTime: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required]),
    endTime: new UntypedFormControl('', [Validators.required]),
  });

  startMinTime = '00:00';
  endMinTime = '00:00';

  fromDate: string;
  todate: string;

  startDateOptions: AirDatepickerOptions = {
    dateFormat: 'dd/MM/yyyy',
    position: 'top left',
  };
  endDateOptions: AirDatepickerOptions = {
    dateFormat: 'dd/MM/yyyy',
    position: 'top left',
  };

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      specificTag: any;
      isEditTag: boolean;
      isService: boolean;
    },
    private catalogMngtService: CatalogManagementService
  ) {
    super();
    if (data?.specificTag) {
      this.specificTag = data.specificTag;
    }
    if (data?.isEditTag) {
      this.isEditTag = data.isEditTag;
    }
    if (data?.isService) {
      this.isService = data.isService;
    }
  }

  ngOnInit(): void {
    this.setStartDateOption(moment().toDate(), false); //start date
    this.setEndDateOption(moment().toDate(), false); //end date

    this.getCategories();

    if (this.specificTag?.length) {
      const specTag = this.specificTag?.[0];
      const startDate = formatDate(
        moment(specTag?.start_date).toDate(),
        'dd/MM/yyyy',
        'en-US'
      );
      const endDate = formatDate(
        moment(specTag?.end_date).toDate(),
        'dd/MM/yyyy',
        'en-US'
      );
      this.tagForm.patchValue({
        categoryID: specTag?.category,
        subCategoryID: specTag?.subCategory,
        startDate: specTag?.start_date ? startDate : null,
        startTime: specTag?.start_time || '',
        endDate: specTag?.end_date ? endDate : null,
        endTime: specTag?.end_time || '',
      });

      const selectedStartDate = specTag?.start_date
        ? [moment(startDate, 'DD/MM/YYYY').toDate()]
        : false;
      const selectedEndDate = specTag?.end_date
        ? [moment(endDate, 'DD/MM/YYYY').toDate()]
        : false;

      console.log('selected tag===>', selectedStartDate, specTag?.end_date);
      this.setStartDateOption(moment().toDate(), selectedStartDate);
      this.setEndDateOption(
        moment(startDate, 'DD/MM/YYYY').toDate(),
        selectedEndDate
      );
    }
    this.getSubCategories(this.tagForm.value.categoryID);

    this.tagForm.controls.startDate.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.setStartMinTime(value);
        }
      });
    this.tagForm.controls.endDate.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.setEndMinTime(value);
        }
      });
  }

  ngOnChanges(): void {
    setTimeout(() => {}, 500);
  }

  getCategories(): void {
    const type = this.isService ? 0 : 1;
    this.catalogMngtService
      .getCategories(type)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response && response?.data) {
          const categorieList = response?.data;
          for (let i = 0; i < categorieList?.length; i++) {
            this.categories.push({
              id: categorieList[i].id,
              text: categorieList[i].name,
            });
          }
          if (this.categories?.length > 0) {
            this.tagForm.patchValue({
              categoryID: this.categories[0].id,
            });
            this.getSubCategories(this.tagForm?.value?.categoryID);
          }
        }
      });
  }

  getSubCategories(catID: any): void {
    this.subCategories = [];
    const payload = {
      catID: catID,
    };
    this.catalogMngtService
      .getSubCategories(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response && response?.data) {
          const subCategoryList = response?.data;
          for (let i = 0; i < subCategoryList?.length; i++) {
            this.subCategories.push({
              id: subCategoryList[i].id,
              text: subCategoryList[i].name,
            });
          }
          if (this.subCategories?.length > 0) {
            this.tagForm.patchValue({
              subCategoryID: this.subCategories[0]?.id,
            });
          }
        }
      });
  }

  reset(): void {
    this.tagForm.reset();
    this.setStartDateOption(moment().toDate(), false);
    this.setEndDateOption(moment().toDate(), false);
  }

  save(): void {
    if (this.tagForm.invalid) {
      this.tagForm.markAllAsTouched();
      return;
    } else {
      const categoryName = this.categories.filter(
        (obj: any) => obj.id == this.tagForm?.value?.categoryID
      )?.[0]?.text;
      const subCategoryName = this.subCategories.filter(
        (obj: any) => obj.id == this.tagForm?.value?.subCategoryID
      )?.[0]?.text;

      const startDate = formatDate(
        moment(this.tagForm?.value?.startDate, 'DD-MM-YYYY').toDate(),
        'yyyy-MM-dd',
        'en-US'
      );

      const endDate = formatDate(
        moment(this.tagForm?.value?.endDate, 'DD-MM-YYYY').toDate(),
        'yyyy-MM-dd',
        'en-US'
      );

      const payload = {
        category: this.tagForm?.value?.categoryID,
        category_name: categoryName ? categoryName : '',
        subCategory: subCategoryName ? this.tagForm?.value?.subCategoryID : '',
        subCategory_name: subCategoryName ? subCategoryName : '',
        start_date: startDate,
        start_time: this.tagForm?.value?.startTime,
        end_date: endDate,
        end_time: this.tagForm?.value?.endTime,
      };
      this.closeModal(payload);
    }
  }

  get f() {
    return this.tagForm.controls;
  }
  closeModal(data?: any) {
    this.dialogRef.close(data);
  }

  startTimeChange() {
    const startDate = this.tagForm.controls.startDate.value;
    const endDate = this.tagForm.controls.endDate.value;
    const startTime = this.tagForm.controls.startTime.value;
    const endTime = this.tagForm.controls.endTime.value;
    const isSameDate = moment(startDate, 'DD/MM/YYYY').isSame(
      moment(endDate, 'DD/MM/YYYY').toDate(),
      'day'
    );

    if (startDate && endDate && isSameDate && startTime >= endTime) {
      this.endMinTime = moment(startTime, 'HH:mm')
        .add(1, 'hours')
        .format('HH:mm');

      this.tagForm.controls.endTime.setValue(this.endMinTime);
    }
  }

  private setStartMinTime(date: string) {
    const isToday = moment(date, 'DD/MM/YYYY').isSame(moment(), 'day');
    if (isToday) {
      this.startMinTime = moment().format('HH:mm');
    } else {
      this.startMinTime = '00:00';
    }
    this.tagForm.controls.startTime.setValue(
      moment().format(this.startMinTime)
    );
    this.startTimeChange();
  }

  private setEndMinTime(date: string) {
    const isToday = moment(date, 'DD/MM/YYYY').isSame(moment(), 'day');
    if (isToday) {
      this.endMinTime = moment().add(1, 'hours').format('HH:mm');
    } else {
      this.endMinTime = '00:00';
    }
    this.tagForm.controls.endTime.setValue(this.endMinTime);
    this.startTimeChange();
  }

  changeDate(id: number): void {
    if (id == 1) {
      this.fromDate = this.tagForm?.value?.startDate;

      let minDate = this.fromDate
        ? moment(this.fromDate, 'DD/MM/YYYY').toDate()
        : moment().toDate();
      let isSameOrAfter = moment(this.fromDate, 'DD/MM/YYYY').isAfter(
        moment(this.tagForm?.value?.endDate, 'DD/MM/YYYY')
      );

      if (isSameOrAfter) {
        this.tagForm.controls['endDate'].reset();
        this.setEndDateOption(minDate);
      } else {
        this.setEndDateOption(
          minDate,
          this.tagForm?.value?.endDate
            ? [moment(this.tagForm?.value?.endDate, 'DD/MM/YYYY').toDate()]
            : false
        );
      }
      this.setStartMinTime(this.fromDate);
    } else {
      this.todate = this.tagForm?.controls['endDate'].value;
      this.setEndMinTime(this.todate);
    }
  }

  private setEndDateOption(
    minDate: Date,
    selectedDates?: Array<string | number | Date> | false,
    dateFormat?: string,
    position?: AirDatepickerPosition
  ) {
    let options: AirDatepickerOptions = {
      ...this.endDateOptions,
      minDate: minDate,
      dateFormat: dateFormat || this.endDateOptions.dateFormat,
      position: position || this.endDateOptions.position,
    };

    if (selectedDates && selectedDates.length) {
      options['selectedDates'] = selectedDates;
    } else {
      options['selectedDates'] = false;
    }

    this.endDateOptions = {
      ...options,
    };
  }

  private setStartDateOption(
    minDate: Date,
    selectedDates?: Array<string | number | Date> | false,
    dateFormat?: string,
    position?: AirDatepickerPosition
  ) {
    let options: AirDatepickerOptions = {
      ...this.startDateOptions,
      minDate: minDate,
      dateFormat: dateFormat || this.endDateOptions.dateFormat,
      position: position || this.endDateOptions.position,
    };

    if (selectedDates && selectedDates.length) {
      options['selectedDates'] = selectedDates;
    } else {
      options['selectedDates'] = false;
    }

    this.startDateOptions = {
      ...options,
    };
  }
}
