<div class="red-select-control">
  <ng-select2
    *ngIf="reviewStatusOptions.length > 0"
    (valueChanged)="changeFilter($event)"
    name=""
    id="reviewStatusID"
    class="select2-control"
    [data]="reviewStatusOptions"
    value="id"
    [(ngModel)]="filter_id"
    [placeholder]="'Select Category'"
  >
  </ng-select2>
</div>
