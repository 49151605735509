<div
  class="custom-dialog-modal modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Assign Image</h4>
      <button type="button" class="close" (click)="close()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <div class="assigned-img-row">
        <ul>
          <li
            class="{{ img.isSelected == true ? 'active' : '' }}"
            *ngFor="let img of existingImages; let ind = index"
          >
            <div
              class="assign-img-info"
              (click)="selectImage(1, img.img_url, ind)"
            >
              <div class="image">
                <img
                  [src]="
                    img.img_url
                      ? img.img_url
                      : 'assets/images/img-placeholder.svg'
                  "
                  alt="product-image"
                />
                <span class="t-badge green">Selected</span>
              </div>
              <div class="img-name">
                {{ img?.file_name }}
              </div>
            </div>
          </li>
          <li
            class="{{ img.isSelected == true ? 'active' : '' }}"
            *ngFor="let img of selectedProductImages; let ind = index"
          >
            <div
              class="assign-img-info"
              (click)="selectImage(2, img.img_url, ind)"
            >
              <div class="image">
                <img
                  [src]="img.img_url ? img.img_url : ''"
                  alt="placeholder-img"
                />
                <span class="t-badge green">Selected</span>
              </div>
              <div class="img-name">
                {{ img?.file_name }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <div class="bottom-btnrow">
        <button (click)="reset()" class="btn t-grey-btn-outline">
          <img src="assets/images/reset-icon.svg" alt="reset" /> RESET
        </button>
        <button (click)="save()" class="btn t-primary-btn">
          <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
        </button>
      </div>
    </div>
  </div>
</div>
<!--


<ul>
    <li *ngFor="let img of existingImages; let ind = index">
        <div class="thumbnail" *ngIf="img.img_url != ''">
            <img [src]="img.img_url ? img.img_url : ''">
            <a href="javascript: void(0)" (click)="removeExistImage(ind)">
                <img src="assets/images/trash-icon.svg" alt="trash">
            </a>
        </div>
    </li>
    <li *ngFor="let img of selectedProductImages; let ind = index">
        <div class="thumbnail">
            <img [src]="img.img_url ? img.img_url : ''">
            <a href="javascript: void(0)"
                (click)="removeSelectedImage(ind)">
                <img src="assets/images/trash-icon.svg" alt="trash">
            </a>
        </div>
    </li>
</ul> -->
