<div
  class="custom-dialog-modal modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ isService ? 'Service' : 'Product' }} Selection
      </h4>
      <button type="button" class="close" (click)="close()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <div class="new-coupon-block" [formGroup]="productForm">
        <div class="form-rows">
          <div class="form">
            <div class="form-group">
              <div class="left-title">
                <label>{{ isService ? 'Service ' : 'Product ' }} SKU </label>
              </div>
              <div class="right-field">
                <div class="form-group">
                  <ng-select2
                    style="width: 100%"
                    *ngIf="!isService"
                    class="select2-control"
                    name=""
                    formControlName="sku"
                    id="productSKUID"
                    [data]="productSKUs"
                    (valueChanged)="getSelectedItem($event)"
                    [placeholder]="'Select'"
                  >
                  </ng-select2>
                  <ng-select2
                    style="width: 100%"
                    *ngIf="serviceSKUs.length > 0"
                    class="select2-control"
                    name=""
                    formControlName="sku"
                    id="serviceSKUID"
                    [data]="serviceSKUs"
                    (valueChanged)="getSelectedItem($event)"
                    [placeholder]="'Select'"
                  >
                  </ng-select2>
                  <div
                    *ngIf="f['sku'].invalid && f['sku'].touched"
                    class="cross-validation-error-message"
                  >
                    <div
                      *ngIf="f['sku'].errors && f['sku'].errors['required']"
                      class="required-note text-danger"
                    >
                      SKU is required.
                    </div>
                  </div>
                </div>
                <div *ngIf="productSKUs.length == 0 && serviceSKUs.length == 0">
                  No skus found for this category. Please config SKU.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-rows">
          <div class="card-box-header">
            <h4 class="card-title">Schedule</h4>
          </div>
          <div class="form">
            <div class="form-group">
              <div class="left-title">
                <label>Start Date</label>
              </div>
              <div class="right-field">
                <div class="date-timerow">
                  <div class="date-timecol">
                    <div class="date-control-group">
                      <input
                        type="text"
                        gfdDateRangePicker
                        #startDateRef="DateRangePickerRef"
                        [options]="startDateOptions"
                        formControlName="startDate"
                        class="form-control date-time-control"
                        placeholder="DD/MM/YYYY"
                        autocomplete="off"
                        (ngModelChange)="changeDate(1)"
                      />
                      <div
                        *ngIf="f['startDate'].invalid && f['startDate'].touched"
                        class="cross-validation-error-message"
                      >
                        <div
                          *ngIf="
                            f['startDate'].errors &&
                            f['startDate'].errors['required']
                          "
                          class="required-note text-danger"
                        >
                          Start date is required.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="date-timecol">
                    <input
                      formControlName="startTime"
                      class="form-control type-time date-time-control"
                      placeholder="Time"
                      gfdEquipTimePicker
                      #startTimeRef="TimePickerRef"
                      [timeformat]="24"
                      [minTime]="startMinTime"
                      [maxTime]="'23:59'"
                      autocomplete="off"
                      (timeChange)="startTimeChange()"
                    />
                    <div
                      *ngIf="f['startTime'].invalid && f['startTime'].touched"
                      class="cross-validation-error-message"
                    >
                      <div
                        *ngIf="
                          f['startTime'].errors &&
                          f['startTime'].errors['required']
                        "
                        class="required-note text-danger"
                      >
                        Start time is required.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="left-title">
                <label>End Date</label>
              </div>
              <div class="right-field">
                <div class="date-timerow">
                  <div class="date-timecol">
                    <div class="date-control-group">
                      <input
                        type="text"
                        gfdDateRangePicker
                        #endDateRef="DateRangePickerRef"
                        [options]="endDateOptions"
                        formControlName="endDate"
                        class="form-control date-time-control"
                        placeholder="DD/MM/YYYY"
                        autocomplete="off"
                      />
                      <div
                        *ngIf="f['endDate'].invalid && f['endDate'].touched"
                        class="cross-validation-error-message"
                      >
                        <div
                          *ngIf="
                            f['endDate'].errors &&
                            f['endDate'].errors['required']
                          "
                          class="required-note text-danger"
                        >
                          End date is required.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="date-timecol">
                    <input
                      formControlName="endTime"
                      class="form-control type-time date-time-control"
                      placeholder="Time"
                      gfdEquipTimePicker
                      #endTimeRef="TimePickerRef"
                      [timeformat]="24"
                      [minTime]="endMinTime"
                      [maxTime]="'23:59'"
                      autocomplete="off"
                    />
                    <div
                      *ngIf="f['endTime'].invalid && f['endTime'].touched"
                      class="cross-validation-error-message"
                    >
                      <div
                        *ngIf="
                          f['endTime'].errors && f['endTime'].errors['required']
                        "
                        class="required-note text-danger"
                      >
                        End time is required.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <div class="bottom-btnrow">
        <button type="button" class="btn t-grey-btn-outline" (click)="reset()">
          <img src="assets/images/reset-icon.svg" alt="reset" /> RESET
        </button>
        <button type="button" class="btn t-primary-btn" (click)="save()">
          <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
        </button>
      </div>
    </div>
  </div>
</div>
