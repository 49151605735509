import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { takeUntil } from 'rxjs';
import { AccountSettingsService } from 'src/app/core/services/account-settings.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-new-email-address-modal',
  templateUrl: './new-email-address-modal.component.html',
})
export class NewEmailAddressModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  emailForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}'),
    ]),
  });

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {},

    private accountSettingService: AccountSettingsService,
    private router: Router,
    private generalService: GeneralService,
    private storageServie: StorageService
  ) {
    super();
  }

  ngOnInit(): void {}

  get f() {
    return this.emailForm.controls;
  }

  sendOTP(): void {
    if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
      return;
    } else {
      const { email } = this.emailForm.getRawValue();
      const seller_id = this.storageServie.getCookie('userID');

      this.accountSettingService
        .editEmail({ email: email, seller_id: seller_id })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response) {
              this.generalService.displaySuccess(response.message);
              this.closeModal({ newEmailID: this.emailForm?.value?.email });
              this.emailForm.reset();
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  closeModal(data?: any): void {
    // this.closeMdl?.nativeElement?.click();
    // this.closeNewEmailModal?.emit(true);
    // this.newEmailID?.emit(this.emailForm?.value?.email);

    this.dialogRef.close(data);
  }
}
