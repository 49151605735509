<div class="modal-header">
  <h4 class="modal-title">VARIANTS</h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    #closeModal
  >
    <img src="assets/images/close-outline.svg" alt="close" />
  </button>
</div>
<div class="modal-body">
  <div class="variant-add-lists">
    <ul>
      <li *ngFor="let s of showList; let ind = index">
        <div class="name">
          <span class="count"></span>
          {{ s.name }}
        </div>
        <a
          href="javascript: void(0)"
          class="btn field-remove-btn"
          (click)="hideShow(s, 1, ind)"
        >
          <img src="assets/images/trash-icon.svg" alt="trash" />
        </a>
      </li>
    </ul>
    <h6 class="small-title">HIDDEN FOR USER</h6>
    <ul>
      <li *ngFor="let h of hideList; let ind = index">
        <div class="name">
          <span class="count"></span>
          {{ h.name }}
        </div>
        <a
          href="javascript: void(0)"
          class="btn field-remove-btn"
          (click)="hideShow(h, 2, ind)"
        >
          <img src="assets/images/trash-icon.svg" alt="trash" />
        </a>
      </li>
    </ul>
  </div>
  <div class="form-group bottom-btnrow mt-4">
    <button class="btn t-grey-btn-outline" (click)="reset()">
      <img src="assets/images/reset-icon.svg" alt="reset" /> RESET</button
    ><button class="btn t-primary-btn" (click)="save()">
      <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
    </button>
  </div>
</div>
