import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { SupportService } from 'src/app/core/services/support.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-ticket-invoice-request-modal',
  templateUrl: './ticket-invoice-request-modal.component.html',
})
export class TicketInvoiceRequestModalComponent
  extends SubscriptionDisposer
  implements OnInit, OnChanges
{
  ticketID: any;
  chatModalObject: any;
  ticketDetails: any;
  config!: AngularEditorConfig;
  invRequestData = new UntypedFormGroup({
    description: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    public generalService: GeneralService,
    private supportService: SupportService,
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: any
  ) {
    super();

    this.ticketID = data.ticketID;
    this.chatModalObject = data.chatModalObject;
  }

  get f() {
    return this.invRequestData.controls;
  }

  ngOnInit(): void {
    if (this.ticketID) {
      this.getTicketDetails();
    }
    this.config = {
      ...this.generalService.getEditorConfig(),
      toolbarPosition: 'bottom',
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ticketID) {
      this.getTicketDetails();
    }
    if (changes['ticketDetails'] && changes['ticketDetails'].currentValue) {
      this.ticketDetails = changes['ticketDetails'].currentValue;
      this.ticketDetails = this.ticketDetails;
      this.getTicketDetails();
    }
  }

  getTicketDetails(): void {
    const payload = {
      ticketID: this.ticketID,
    };
    this.supportService
      .getTicketDetails(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          if (res) {
            this.ticketDetails = res?.data;
            this.ticketDetails?.ticketfiles?.map((item: any) => {
              let arr = [];
              arr = item.file_name?.split('.');
              item.file_type = arr?.[arr.length - 1];
            });
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  sendInvoiceRequest(): void {
    if (this.invRequestData.invalid) {
      this.invRequestData.markAllAsTouched();
      return;
    } else {
      const payload = {
        ticketID: this.ticketID,
        invoice_request_description: this.invRequestData.value.description,
      };
      this.supportService
        .ticketInvoiceRequestSend(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res) {
              this.generalService.displaySuccess(res?.message);
              this.closeModal();
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
