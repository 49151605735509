<div
  class="custom-dialog-modal modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Ticket #{{ ticketDetails?.ticket_number }}</h4>
      <button type="button" class="close" (click)="closeModal()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <div class="add-new-ticket-block" [formGroup]="invRequestData">
        <div class="request-invoice-ticket ticket-chat-thread">
          <div class="head-info">
            <div class="personal-info">
              <h5 class="name" *ngIf="ticketDetails?.full_name">
                {{ ticketDetails?.full_name }}
              </h5>
              <ul>
                <li *ngIf="ticketDetails?.email">{{ ticketDetails?.email }}</li>
                <li *ngIf="ticketDetails?.contact?.internationalNumber">
                  {{ ticketDetails?.contact?.internationalNumber }}
                </li>
              </ul>
            </div>
            <div class="ordernumber" *ngIf="ticketDetails?.order_number">
              Order Number #{{ ticketDetails?.order_number }}
            </div>
          </div>
          <div class="thread-block">
            <div class="date">
              {{ ticketDetails?.createdAt | date : 'dd/MM/yyyy' }}
            </div>
            <h4 class="title">{{ ticketDetails?.subject }}</h4>
            <p class="desc" [innerHtml]="ticketDetails?.description"></p>
            <div class="image-row">
              <div
                class="img-item"
                *ngFor="let i of ticketDetails?.ticketfiles"
              >
                <img
                  *ngIf="i.img_url && i.file_type !== 'pdf'"
                  src="{{ i.img_url }}"
                  alt="ticket-img"
                />
                <a href="{{ i.img_url }}" target="_blank">
                  <img
                    *ngIf="i.img_url && i.file_type === 'pdf'"
                    src="assets/images/ticket-pdf-icon.svg"
                    alt="ticket-img"
                /></a>
                <!-- <span class="count">4</span> -->
              </div>
            </div>
          </div>
        </div>
        <div class="form-group chat-window-modal mb-0">
          <!-- <angular-editor formControlName="description" [config]="config">
                </angular-editor>
                <div *ngIf="f['description'].invalid && (f['description'].touched)"
                    class="cross-validation-error-message">
                    <div *ngIf="f['description'].errors && f['description'].errors['required']"
                        class="required-note text-danger">
                        Description is required.
                    </div>
                </div> -->
          <app-ticket-chat-configuration-modal
            (imgUploaded)="getTicketDetails()"
            [chatModalObject]="chatModalObject"
          ></app-ticket-chat-configuration-modal>
        </div>
        <!-- <div class="bottom-btnrow" (click)="sendInvoiceRequest()">
                <button class="btn t-primary-btn"><img src="assets/images/paper-plane-outline.svg"> Send</button>
            </div> -->
      </div>
    </div>
  </div>
</div>
