<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Request Admin to Edit</h4>
    <button
      type="button"
      class="close"
      #closeModal
      data-dismiss="modal"
      aria-label="Close"
    >
      <img src="assets/images/close-outline.svg" alt="close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="commit-modal-content">
      <p class="sm-text">
        Each request will open a support ticket. By sending the request, you are
        giving permission to the agent to enter values that they deem
        appropriate.
      </p>
    </div>
    <div class="bottom-btnrow justify-content-start">
      <button class="btn t-primary-light text-capitalize">Cancel</button>
      <button
        class="btn t-primary-btn text-capitalize"
        (click)="openTicketModal()"
      >
        Send request
      </button>
    </div>
  </div>
</div>
