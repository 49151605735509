<div
  class="custom-dialog-modal modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title text-uppercase">Enter A new Email Address</h4>
      <button type="button" class="close" (click)="closeModal()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <div class="profile-enter-new-email" [formGroup]="emailForm">
        <p class="text-center">
          Verification OTP will be sent to this new email address
        </p>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Email"
            formControlName="email"
          />
          <div
            *ngIf="f['email'].invalid && f['email'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['email'].errors?.['required']"
              class="required-note text-danger"
            >
              Email is required *
            </div>
            <div
              *ngIf="f['email'].errors?.['email']"
              class="required-note text-danger"
            >
              Valid Email is required *
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="bottom-btnrow">
        <button class="btn t-primary-btn" (click)="sendOTP()">
          <img src="assets/images/tickmark-white.svg" alt="send" /> Send OTP
        </button>
      </div>
    </div>
  </div>
</div>
