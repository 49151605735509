import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { GeneralService } from 'src/app/core/services/general.service';

@Component({
  selector: 'app-add-custom-information-modal',
  templateUrl: './add-custom-information-modal.component.html',
})
export class AddCustomInformationModalComponent implements OnInit, OnChanges {
  editCustomInfoId: any;
  customInfoList: Array<any> = [];

  customInfo = new UntypedFormGroup({
    label: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl('', [Validators.required]),
    type: new UntypedFormControl('', [Validators.required]),
    value: new UntypedFormControl(''),
  });

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      editCustomInfoId: string;
      customInfoList: any[];
    },
    private generalService: GeneralService
  ) {
    if (data?.customInfoList) {
      this.customInfoList = data.customInfoList;
    }
    if (data?.editCustomInfoId) {
      this.editCustomInfoId = data.editCustomInfoId;
    }
    this.patchFormValue();
  }

  get f() {
    return this.customInfo.controls;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.patchFormValue();
  }

  patchFormValue() {
    if (this.editCustomInfoId) {
      const value = this.customInfoList.find(
        (f) => f.id === this.editCustomInfoId
      );
      this.customInfo.patchValue({
        label: value.label || '',
        title: value.title || '',
        type: value.type || '',
        value: value.value || '',
      });
    }
  }

  onTypeChange(value: any): void {
    this.customInfo.patchValue({
      type: value,
    });
  }

  save(): void {
    if (this.customInfo.invalid) {
      this.customInfo.markAllAsTouched();
      return;
    }
    const value = this.customInfo.value;
    if (
      this.customInfoList.find(
        (f) =>
          f.id !== this.editCustomInfoId &&
          (f.label.toLowerCase() === value.label.toLowerCase() ||
            f.title.toLowerCase() === value.title.toLowerCase())
      )
    ) {
      this.generalService.displayError('Label or title is already taken');
      return;
    }
    this.close({
      data: {
        id: this.editCustomInfoId || '',
        ...value,
      },
    });
  }

  reset(): void {
    this.customInfo.reset();
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }
}
