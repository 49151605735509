<div
  class="custom-dialog-modal modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div *ngIf="!isShowSMSOTP">
      <div class="modal-header">
        <h4 class="modal-title text-uppercase">VERIFY new Email Address</h4>
        <button
          type="button"
          (click)="close()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeMdl
        >
          <img src="assets/images/close-outline.svg" alt="close" />
        </button>
      </div>
      <div class="modal-body">
        <div class="profile-enter-new-email">
          <p class="text-center">
            OTP has been sent to register below email address
          </p>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Email"
              [(ngModel)]="email"
              [disabled]="true"
            />
          </div>
          <div class="otp-form-group" [formGroup]="otpForm">
            <div class="otp-input-control">
              <input
                type="text"
                class="form-control"
                maxlength="1"
                formControlName="digitOne"
                #otpInput
                (keypress)="generalService.numberOnly($event)"
                (input)="handleInput(1)"
              />
              <input
                type="text"
                class="form-control"
                maxlength="1"
                formControlName="digitTwo"
                #otpInput
                (keypress)="generalService.numberOnly($event)"
                (input)="handleInput(2)"
              />
              <input
                type="text"
                class="form-control"
                maxlength="1"
                formControlName="digitThree"
                #otpInput
                (keypress)="generalService.numberOnly($event)"
                (input)="handleInput(3)"
              />
              <input
                type="text"
                class="form-control"
                maxlength="1"
                formControlName="digitFour"
                #otpInput
                (keypress)="generalService.numberOnly($event)"
              />
            </div>
            <a
              href="javascript: void(0)"
              (click)="resendOTP()"
              class="resend-otp"
              >Resend OTP in {{ counter }}s</a
            >
          </div>
          <div
            *ngIf="
              (f['digitOne'].invalid && f['digitOne'].touched) ||
              (f['digitTwo'].invalid && f['digitTwo'].touched) ||
              (f['digitThree'].invalid && f['digitThree'].touched) ||
              (f['digitFour'].invalid && f['digitFour'].touched)
            "
            class="cross-validation-error-message mb-2"
          >
            <div class="required-note text-danger">
              Please enter 4 digit OTP *
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="bottom-btnrow mt-4">
          <button class="btn t-primary-btn" (click)="verify()">
            <img src="assets/images/tickmark-white.svg" alt="verify" /> Verify
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="isShowSMSOTP">
      <div class="modal-header">
        <h4 class="modal-title text-uppercase">
          VERIFY OTP for new email address
        </h4>
        <button
          type="button"
          (click)="close()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeSMSMdl
        >
          <img src="assets/images/close-outline.svg" alt="close" />
        </button>
      </div>
      <div class="modal-body">
        <div class="verify-email-block">
          <p>
            OTP has been sent to register mobile Number
            <span class="d-inline-block">{{ encodeMobile }}</span> <br />
            to verify the new email address
          </p>
          <div class="otp-form-group" [formGroup]="otpForm">
            <div class="otp-input-control">
              <input
                type="text"
                class="form-control"
                maxlength="1"
                formControlName="digitOne"
                #otpInput
                (keypress)="generalService.numberOnly($event)"
                (input)="handleInput(1)"
              />
              <input
                type="text"
                class="form-control"
                maxlength="1"
                formControlName="digitTwo"
                #otpInput
                (keypress)="generalService.numberOnly($event)"
                (input)="handleInput(2)"
              />
              <input
                type="text"
                class="form-control"
                maxlength="1"
                formControlName="digitThree"
                #otpInput
                (keypress)="generalService.numberOnly($event)"
                (input)="handleInput(3)"
              />
              <input
                type="text"
                class="form-control"
                maxlength="1"
                formControlName="digitFour"
                #otpInput
                (keypress)="generalService.numberOnly($event)"
              />
            </div>
            <div
              *ngIf="
                (f['digitOne'].invalid && f['digitOne'].touched) ||
                (f['digitTwo'].invalid && f['digitTwo'].touched) ||
                (f['digitThree'].invalid && f['digitThree'].touched) ||
                (f['digitFour'].invalid && f['digitFour'].touched)
              "
              class="cross-validation-error-message mb-2"
            >
              <div class="required-note text-danger">
                Please enter 4 digit OTP *
              </div>
            </div>
            <a
              href="javascript: void(0)"
              (click)="resendOTP()"
              class="resend-otp"
              >Resend OTP in {{ counter }}s</a
            >
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="bottom-btnrow">
          <button class="btn t-primary-btn" (click)="verify()">
            <img src="assets/images/tickmark-white.svg" alt="verify" />
            Continue to Change Email
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
