import { DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { WalletService } from 'src/app/core/services/wallet.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-add-bank-account-modal',
  templateUrl: './add-bank-account-modal.component.html',
})
export class AddBankAccountModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  @ViewChild('closeMdl') closeMdl: any;
  @Output('update') updateAccountDetails = new EventEmitter();

  bankNameList: any = [];

  bankForm = new UntypedFormGroup({
    bank: new UntypedFormControl(0, [Validators.required]),
    accountNumber: new UntypedFormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    iban: new UntypedFormControl('', [Validators.required]),
    swiftCode: new UntypedFormControl(''),
    setAsDefault: new UntypedFormControl(false),
  });

  constructor(
    private storageService: StorageService,
    public generalService: GeneralService,
    private walletService: WalletService,
    public dialogRef: DialogRef<any>
  ) {
    super();
  }

  get f() {
    return this.bankForm.controls;
  }

  ngOnInit(): void {
    this.getBanks();
  }

  save(): void {
    if (this.bankForm.invalid) {
      this.bankForm.markAllAsTouched();
      return;
    } else {
      const { bank, accountNumber, name, iban, swiftCode, setAsDefault } =
        this.bankForm.value;
      const payload = {
        bank: bank,
        account_number: accountNumber,
        name: name,
        iban: iban,
        swift_code: swiftCode,
        set_default: setAsDefault,
        seller: this.storageService.getCookie('userID'),
      };
      this.walletService
        .setBankAccount(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response) {
              this.generalService.displaySuccess(response?.message);
              this.closeModal(true);
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  closeModal(data?): void {
    this.dialogRef.close(data);
  }

  getBanks(): void {
    this.walletService
      .getBanks()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          if (response) {
            this.bankNameList = response?.data;
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }
}
