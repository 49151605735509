import { DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { takeUntil } from 'rxjs';
import { AccountSettingsService } from 'src/app/core/services/account-settings.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-update-mobile-modal',
  templateUrl: './update-mobile-modal.component.html',
})
export class UpdateMobileModalComponent
  extends SubscriptionDisposer
  implements OnInit, OnChanges
{
  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef<HTMLInputElement>>;
  // @ViewChild('closeModal') closeModal: any;
  // @Output() closeMobileOTPModal = new EventEmitter();

  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.UnitedArabEmirates,
    CountryISO.UnitedKingdom,
  ];
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  separateDialCode = false;
  isShowSMSOTP = false;

  // verify
  otp: any = '';
  counter = 60;
  resendClicked = false;
  otpForm = new UntypedFormGroup({
    digitOne: new UntypedFormControl('', [Validators.required]),
    digitTwo: new UntypedFormControl('', [Validators.required]),
    digitThree: new UntypedFormControl('', [Validators.required]),
    digitFour: new UntypedFormControl('', [Validators.required]),
  });
  otpArray = ['digitOne', 'digitTwo', 'digitThree', 'digitFour'];

  mobileForm = new UntypedFormGroup({
    mobile: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: DialogRef<any>,
    public generalService: GeneralService,
    private storageServie: StorageService,
    private accountSettingService: AccountSettingsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.resendClicked = true;
  }

  ngOnChanges(): void {
    this.counter = 60;
    if (this.isShowSMSOTP) {
      this.startTimer();
    }
  }

  get f() {
    return this.mobileForm.controls;
  }

  sendOTP(): void {
    if (this.mobileForm?.invalid) {
      this.mobileForm.markAllAsTouched();
      return;
    } else {
      const seller_id = this.storageServie.getCookie('userID');
      const payload = {
        seller_id: seller_id,
        mobile_number: this.mobileForm?.value?.mobile,
      };
      this.accountSettingService
        .editMobileNumber(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response) {
              this.generalService.displaySuccess(response?.message);
              this.isShowSMSOTP = true;
              this.startTimer();
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  verify(): void {
    if (this.otpForm.invalid) {
      this.otpForm.markAllAsTouched();
      return;
    } else {
      this.otp =
        this.otpForm.value.digitOne +
        '' +
        this.otpForm.value.digitTwo +
        '' +
        this.otpForm.value.digitThree +
        '' +
        this.otpForm.value.digitFour;
      const seller_id = this.storageServie.getCookie('userID');
      const payload = {
        otp: this.otp,
        mobile_number: this.mobileForm?.value?.mobile,
        seller_id: seller_id,
      };
      this.accountSettingService
        .verifyMobileNumber(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res) {
              this.otp = '';
              this.otpForm.reset();
              this.isShowSMSOTP = false;
              this.generalService.displaySuccess(res?.message);
              this.closeMdl(true);
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  closeMdl(data?: any): void {
    this.dialogRef.close(data);
  }

  handleInput(ele: number): void {
    if (this.otpForm.controls[this.otpArray[ele - 1]].valid) {
      setTimeout(() => {
        this.otpInputs.get(ele)?.nativeElement.focus();
      });
    }
  }

  resendOTP(): void {
    if (this.resendClicked) {
      return;
    }
    if (!this.mobileForm?.value?.mobile) {
      this.generalService.displaySuccess('Please enter mobile number');
      return;
    } else {
      this.resendClicked = true;
      const seller_id = this.storageServie.getCookie('userID');
      const payload = {
        seller_id: seller_id,
        mobile_number: this.mobileForm?.value?.mobile,
      };
      this.accountSettingService
        .editMobileNumber(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response) {
              this.generalService.displaySuccess(response?.message);
              this.startTimer();
            }
          },
          (error) => {
            this.resendClicked = false;
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  get g() {
    return this.otpForm.controls;
  }

  startTimer() {
    const otpTimer = setInterval(() => {
      if (this.counter > 0) {
        this.counter--;
      } else if (this.counter == 0) {
        this.counter = 60;
        clearInterval(otpTimer);
        this.resendClicked = false;
      } else {
        this.counter = 60;
        clearInterval(otpTimer);
        this.resendClicked = false;
      }
    }, 1000);
  }
}
