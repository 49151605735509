import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(private httpClient: HttpClient) {}

  addLog(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/logs`,
      payload
    );
  }
}
