import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  constructor(private httpClient: HttpClient) {}

  // Tickets
  getTickets(vendorId: string, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/tickets/${vendorId}?${payload}`
    );
  }

  getTicketDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/ticketDetails/${payload.ticketID}`
    );
  }

  addTicket(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/tickets `,
      payload
    );
  }

  clostTicket(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/tickets/closeTicket/${payload.ticketID}`,
      payload
    );
  }

  uploadTicketFile(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/uploadTicketFile  `,
      payload
    );
  }

  ticketInvoiceRequestSend(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/invoiceRequest/${payload.ticketID} `,
      payload
    );
  }

  getInvoiceRequestTickets(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/invoiceRequestList/${vendorID}?${payload}`
    );
  }

  uploadInvoiceFile(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/uploadInvoiceFile  `,
      payload
    );
  }

  getTaxInvoiceDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/taxInvoiceDetails/${payload.invoiceID}`
    );
  }

  getMessages(ticketId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/getMessages/${ticketId}`
    );
  }

  sendMessage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/sendMessage  `,
      payload
    );
  }

  readMessages(ticketId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/readTicket/${ticketId}`
    );
  }

  getOrderReferenceList(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/orderReferenceList`,
      payload
    );
  }

  getOrderWiseItemReferenceList(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/itemReferenceList`,
      payload
    );
  }

  getTableFields(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/getTableFields?name=${payload.module}`
    );
  }
}
