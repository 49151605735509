import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CredentialService } from '@app/core/services/credential.service';

import { Select2OptionData } from 'ng-select2';
import { GeneralService } from 'src/app/core/services/general.service';
import { PackageConfigurationModalComponent } from '../package-configuration-modal/package-configuration-modal.component';
import { OpenModalConfig } from '@app/shared/directives/open-modal.directive';
import { AssignVariantImageModalComponent } from '../assign-variant-image-modal/assign-variant-image-modal.component';
import { EditVariantOptionModalComponent } from '../edit-variant-option-modal/edit-variant-option-modal.component';
import { IUserInfo } from '@app/core/model/common-model';

@Component({
  selector: 'app-add-service-variant-modal',
  templateUrl: './add-service-variant-modal.component.html',
  styleUrls: ['./add-service-variant-modal.component.scss'],
})
export class AddServiceVariantModalComponent implements OnInit, OnChanges {
  @ViewChild('openEditModal') openEditModal: any;
  @Output() variants = new EventEmitter();
  @Output() packageList = new EventEmitter();
  @Input() variantList: any;
  @Input() customPackages: any;
  @Input() oldImages: any;
  @Input() newImages: any;
  @Input() categoryID: any;

  isCustomVariantExpand = false;
  optionNameList: Array<Select2OptionData> = [
    {
      id: '0',
      text: 'Select',
    },
    {
      id: '1',
      text: 'Packages',
    },
    {
      id: '2',
      text: 'Packages + Addons',
    },
  ];
  customVariantName: any = '';
  selectedVariant = 0;
  selectedOption = {
    id: '',
    name: '',
    values: [] as Array<any>,
    isEdit: false,
  };
  optionValue: any = '';
  finalVariantList: any = [];
  allOptionValues: any = [];
  editOptionValueData: any;
  isClickAddVariantOption: any = false;
  isEditPackageName: any = false;
  optionValueID: any;
  colorCode: any = '#FF0000';
  package = {
    id: 0,
    name: '',
    addonName: '',
    columns: [
      {
        id: 1,
        name: 'Image',
        isShow: true,
      },
      {
        id: 2,
        name: 'Name',
        isShow: true,
      },
      {
        id: 3,
        name: 'Sku',
        isShow: true,
      },
      {
        id: 4,
        name: 'Price',
        isShow: true,
      },
      {
        id: 5,
        name: 'Sale Price',
        isShow: true,
      },
      {
        id: 6,
        name: 'Tax',
        isShow: true,
      },
      {
        id: 7,
        name: 'Total',
        isShow: true,
      },
      {
        id: 8,
        name: 'Available Stock',
        isShow: true,
      },
    ],

    optionValues: [],
    addonOptionValues: [],
  };
  packages: any = [];
  packageSettingID: any;
  packageOptionValues: any = [];
  packageOptionID: any;
  userDetails: IUserInfo;
  removeTaxPercentage = true;
  editOptionValues: any;
  selectedPackage: any = {
    name: '',
    id: '',
  };
  isAddonImg: any;
  isVariantImg: any;
  assignImgPackageIndex: any;
  selectAssignedImgUrl: any = '';

  customPackageAddModalData: OpenModalConfig = {
    modalComponent: PackageConfigurationModalComponent,
    modalData: {
      selectedPackageData: this.selectedPackage,
    },
    width: '64rem',
  };

  variantOptionModalData: OpenModalConfig = {
    modalComponent: EditVariantOptionModalComponent,
    modalData: {},
  };

  assignVariantImageModalData: OpenModalConfig = {
    modalComponent: AssignVariantImageModalComponent,
    modalData: {},
  };
  taxPercentage: number = 0;

  constructor(
    public generalService: GeneralService,
    public crendentialService: CredentialService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.categoryID = changes['categoryID']
      ? changes['categoryID'].currentValue
      : this.categoryID;
    this.setTaxercentageIfAvilable(this.categoryID);
  }

  ngOnInit(): void {
    this.userDetails = this.crendentialService?.userDetails;
    if (this.variantList) {
      this.finalVariantList = this.variantList?.optionValues
        ? this.variantList?.optionValues
        : [];
      this.optionNameList =
        this.variantList?.options?.length > 0
          ? this.variantList?.options
          : this.optionNameList;
      if (this.variantList?.all?.length > 0) {
        this.allOptionValues = this.variantList?.all;
      } else {
        this.getCombinationData();
      }
    }
    if (this.customPackages?.length > 0) {
      this.packages =
        this.customPackages?.length > 0 ? this.customPackages : [];
    }
    this.setTaxercentageIfAvilable(this.categoryID);
  }

  setTaxercentageIfAvilable(categoryId?: string) {
    this.taxPercentage = this.userDetails?.TAX_PERCENTAGE
      ? this.userDetails?.TAX_PERCENTAGE
      : 0;
    if (this.taxPercentage > 0 && categoryId !== null) {
      const taxExemptions: string[] = this.userDetails?.TAX_EXEMPTIONS;

      const targetIdToCheck: string = categoryId;

      const isIdPresent: boolean = taxExemptions.includes(targetIdToCheck);

      if (isIdPresent) {
        this.removeTaxPercentage = true;
        this.taxPercentage = 0;
      } else {
        this.removeTaxPercentage = false;
        this.taxPercentage = this.userDetails?.TAX_PERCENTAGE;
      }
    } else {
      this.taxPercentage = this.userDetails?.TAX_PERCENTAGE;
    }
  }

  resetPackageForm(): void {
    this.package.name = '';
    this.package.id = 0;
    this.package.addonName = '';
    this.package.optionValues = [];
    this.package.addonOptionValues = [];
    this.isEditPackageName = false;
  }

  addPackage(): void {
    if (!this.package.id) {
      if (!this.package.name) {
        this.generalService.displayError('Please enter packge name');
      } else if (!this.package.addonName && this.selectedVariant == 2) {
        this.generalService.displayError('Please enter addon name');
      } else {
        const isDuplicatePkg =
          this.packages.filter((c: any) => c.name === this.package.name)
            ?.length > 0;
        if (!isDuplicatePkg) {
          this.packages.push({
            id: this.packages?.length + 1,
            name: this.package?.name,
            addonName: this.package?.addonName ? this.package?.addonName : '',
            columns: this.package?.columns,
            optionValues: this.package?.optionValues,
            addonOptionValues: this.package?.addonOptionValues
              ? this.package?.addonOptionValues
              : [],
            variantType: this.selectedVariant,
          });
          this.resetPackageForm();
        } else {
          this.generalService.displayError('Package name is already exist.');
        }
      }
    } else {
      this.packages?.map((item: any) => {
        if (item.id == this.package.id) {
          (item.name = this.package?.name),
            (item.addonName = this.package?.addonName
              ? this.package?.addonName
              : '');
        }
      });
      this.resetPackageForm();
    }
    this.packageList?.emit(this.packages);
  }

  addCustomVariantName(): void {
    if (!this.customVariantName) {
      this.generalService.displayError('Please enter variant name.');
      return;
    } else {
      const isDuplicate =
        this.optionNameList.filter(
          (c: any) => c.text === this.customVariantName
        )?.length > 0;
      if (!isDuplicate) {
        this.optionNameList.push({
          id: (this.optionNameList?.length + 1)?.toString(),
          text: this.customVariantName,
        });
        this.isCustomVariantExpand = false;
        this.removeCustomVariantName();
      } else {
        this.generalService.displayError('Name is already exist.');
        this.removeCustomVariantName();
      }
    }
  }

  removeCustomVariantName(): void {
    this.customVariantName = '';
  }

  removeSeletecVariantName(): void {
    // let selectedVariantIndex = this.optionNameList.findIndex((o: any) => o.id == this.selectedVariant);
    // this.optionNameList.splice(selectedVariantIndex, 1)
    this.selectedVariant = 0;
  }

  changeVariantOptionName(event: any): void {
    this.selectedOption.id = event;
    this.selectedOption.name = this.optionNameList?.filter(
      (o: any) => o.id == event
    )?.[0]?.text;
    this.isClickAddVariantOption = true;
    this.resetOptionValueForm();
  }

  removeOptionValue(index: any): void {
    this.selectedOption?.values?.splice(index, 1);
  }

  addOptionValue(): void {
    if (!this.optionValue) {
      this.generalService.displayError('Please enter option value.');
      return;
    }
    if (this.selectedOption?.values[0]?.id == '') {
      this.selectedOption?.values?.splice(0, 1);
    }
    this.selectedOption?.values.push({
      id: this.selectedOption?.values?.length + 1,
      name: this.optionValue,
      color_code: this.colorCode ? this.colorCode : '#000',
    });
    this.optionValue = '';
    this.colorCode = '';
  }

  resetOptionValueForm(): void {
    this.selectedOption.values = [];
  }

  updateOptionValue(): void {
    if (this.selectedOption.isEdit) {
      for (let a = 0; a < this.finalVariantList.length; a++) {
        const obj = this.finalVariantList[a];
        if (obj.option_name == this.selectedOption.name) {
          obj.option_name = this.selectedOption.name;
          obj.option_values = this.selectedOption.values;
        }
      }
    } else {
      if (!this.selectedOption.id || this.selectedOption.id === '0') {
        this.generalService.displayError('Please select variant option type.');
        return;
      } else if (!this.selectedOption.values.length) {
        this.generalService.displayError(
          'Please add variant option for specific type'
        );
        return;
      } else {
        // if selected
        const index = this.finalVariantList?.findIndex(
          (f: any) => f.option_id === this.selectedOption.id
        );
        if (index >= 0) {
          const optionValues = this.finalVariantList[index].option_values;
          this.selectedOption?.values.forEach((f, i) => {
            this.finalVariantList[index].option_values.push({
              id: Number(+(optionValues[optionValues.length - 1]?.id || 0) + 1),
              name: f.name,
            });
          });
        } else {
          this.finalVariantList.push({
            id: this.finalVariantList.length + 1,
            option_name: this.selectedOption.name,
            option_id: this.selectedOption.id,
            option_values: this.selectedOption?.values,
          });
        }
      }
    }
    this.selectedVariant = 0;
    this.selectedOption.id = '';
    this.selectedOption.name = '';
    this.selectedOption.isEdit = false;
    this.optionValue = '';
    this.resetOptionValueForm();
    // this.getAllOptionWithValues();
    this.getCombinationData();
  }

  getAllOptionWithValues(): void {
    this.allOptionValues = [];
    for (let i = 0; i < this.finalVariantList.length; i++) {
      const iobj = this.finalVariantList[i];
      for (let j = 0; j < iobj?.option_values?.length; j++) {
        const jobj = iobj?.option_values[j];
        this.allOptionValues.push({
          id: this.allOptionValues.length + 1,
          name: iobj.option_name + ' | ' + jobj.name,
          option_id: Number(iobj.id),
          option_name: iobj.option_name,
          value_id: Number(jobj.id),
          value_name: jobj.name,
          value_color_code: jobj.color_code,
          price: '',
          available_stock: '',
          sku: '',
          barcode: '',
        });
      }
    }
  }

  editVartiant(index: any): void {
    this.selectedOption.values = this.finalVariantList[index]?.option_values;
    this.selectedOption.id = this.finalVariantList[index]?.option_id;
    this.selectedOption.name = this.finalVariantList[index]?.option_name;
    this.selectedOption.isEdit = true;
    this.isClickAddVariantOption = true;
  }

  removeOptionValueVariant(index: any): void {
    this.finalVariantList.splice(index, 1);
    this.getCombinationData();
  }

  getVariantOptionModalData(id: any): OpenModalConfig {
    this.editOptionValueData = this.allOptionValues?.filter(
      (obj: any) => obj.id == id
    )?.[0];
    this.variantOptionModalData = {
      ...this.variantOptionModalData,
      modalData: {
        ...this.variantOptionModalData.modalData,
        editOptionValueData: this.editOptionValueData,
      },
    };
    return this.variantOptionModalData;
  }

  scroll(element: HTMLElement): void {
    if (element) {
      const headerHeight = 180; // Adjust this value to match your header's height
      const sectionTop = element.getBoundingClientRect().top + window.scrollY;
      const yOffset = sectionTop - headerHeight;

      window.scrollTo({
        top: yOffset,
        behavior: 'smooth',
      });
      this.isClickAddVariantOption = true;
    }
  }

  getUpdatedOptionValueData(data: any): void {
    this.allOptionValues?.map((obj: any) => {
      if (obj?.id == data?.id) {
        obj.price = data.price;
        obj.sku = data.sku;
        obj.barcode = data.barcode;
        obj.available_stock = data.available_stock;
      }
    });
  }

  emitAllOptionValueData(): void {
    const payload = {
      all: this.allOptionValues,
      options: this.optionNameList,
      optionValues: this.finalVariantList,
    };
    this.variants.emit(payload);
  }

  getAssignedImg(data: any): void {
    this.allOptionValues?.map((obj: any) => {
      if (obj?.id == data?.optionValueID) {
        obj.img_url = data?.img_url;
        obj.new_img_id = data?.new_img_id;
      }
    });
  }

  getAssignVariantImageModalData(
    id: any,
    type: any,
    pindex: any,
    img: any
  ): OpenModalConfig {
    this.optionValueID = id;
    this.isAddonImg = type == 3 ? true : false;
    this.isVariantImg = type == 1 ? true : false;
    this.assignImgPackageIndex = pindex;
    this.selectAssignedImgUrl = img;
    this.assignVariantImageModalData = {
      ...this.assignVariantImageModalData,
      modalData: {
        ...this.assignVariantImageModalData.modalData,
        selectAssignedImgUrl: this.selectAssignedImgUrl,
        assignImgPackageIndex: this.assignImgPackageIndex,
        optionValueID: this.optionValueID,
        existingImages: this.oldImages,
        isVariantImg: this.isVariantImg,
        isAddonImg: this.isAddonImg,
        selectedProductImages: this.newImages,
      },
    };

    return this.assignVariantImageModalData;
  }

  getCombinationData() {
    const arr: any = [];
    this.allOptionValues = [];
    for (let i = 0; i < this.finalVariantList?.length; i++) {
      const iobj = this.finalVariantList?.[i];
      arr.push({
        values: iobj.option_values,
        option_id: Number(iobj.option_id),
        option_name: iobj.option_name,
      });
    }
    const final = this.combinations(arr);
    const finalArr: any[] = [];
    final.forEach((f: any) => {
      const valueNames = [];
      const valueIDs = [];
      const optionValueData: Array<any> = [];
      for (const o in f) {
        valueNames.push(f[o].name);
        valueIDs.push(f[o].id);
        optionValueData.push(f[o]);
      }
      finalArr.push({
        name: valueNames.join('/'),
        optionValueData,
      });
    });
    for (let j = 0; j < finalArr.length; j++) {
      this.allOptionValues.push({
        id: this.allOptionValues.length + 1,
        name: finalArr[j]?.name,
        price: '',
        available_stock: '',
        sku: '',
        barcode: '',
        optionValueData: finalArr[j]?.optionValueData,
      });
    }
    this.emitAllOptionValueData();
  }

  combinations(arr: any) {
    return (function recurse(keys) {
      if (!keys.length) return [{}];
      const result = recurse(keys.slice(1));
      const optionValuesFinal = [];
      optionValuesFinal.push({
        option_id: arr.option_id,
      });
      const { option_id, option_name } = arr[keys[0]];
      return arr[keys[0]].values?.reduce(
        (acc: any, value: any) =>
          acc.concat(
            result.map((item: any) =>
              Object.assign({}, item, {
                [keys[0]]: { ...value, option_id, option_name },
              })
            )
          ),
        []
      );
    })(Object.keys(arr));
  }

  getShowCloumn(data: any): void {
    this.packages.map((item: any) => {
      if (item.id == data.packageID) {
        item.columns = data?.columns;
      }
    });
  }

  tableSetting(id: any): void {
    this.packageSettingID = id;
  }

  createRow(data: any, pkgID: any): void {
    const packageValue = data?.packageOptionValues;
    if (packageValue) {
      this.packages?.map((item: any) => {
        if (item.id == pkgID) {
          item.optionValues.push({
            img_url: '',
            name: packageValue?.title,
            price: packageValue?.regular_prisce,
            available_stock: packageValue?.stock,
            sale_price: packageValue?.sale_price,
            sku: packageValue?.sku,
            id: item.optionValues?.length + 1,
          });
        }
      });
    }
  }

  createAddonRow(data: any, pkgID: any): void {
    const addonValue = data?.packageOptionValues;
    if (addonValue) {
      this.packages?.map((item: any) => {
        if (item.id == pkgID) {
          item.addonOptionValues?.push({
            img_url: '',
            name: addonValue?.title,
            price: addonValue?.regular_price,
            available_stock: addonValue?.stock,
            sku: addonValue?.sku,
            id: item.addonOptionValues?.length + 1,
            sale_price: addonValue?.sale_price,
          });
        }
      });
    }
  }

  changePackageOptionValue(): void {
    this.packageList?.emit(this.packages);
  }

  changeAddonOptionValue(): void {
    this.packageList?.emit(this.packages);
  }

  removePackageOption(index: any, pindex: any): void {
    this.packages[pindex]?.optionValues?.splice(index, 1);
    this.packageList?.emit(this.packages);
  }

  removeAddonOption(index: any, pindex: any): void {
    this.packages[pindex]?.addonOptionValues?.splice(index, 1);
    this.packageList?.emit(this.packages);
  }

  getPackageModalConfig(data: any, packages: any): OpenModalConfig {
    this.editOptionValues = data;
    this.packageOptionID = data?.id;
    this.selectedPackage = packages;
    this.customPackageAddModalData = {
      ...this.customPackageAddModalData,

      modalData: {
        ...this.customPackageAddModalData.modalData,
        selectedPackageData: this.selectedPackage,
        packageOptionID: this.packageOptionID,
        packageOptionValues: this.editOptionValues,
        categoryID: this.categoryID,
      },
      width: '64rem',
    };
    return this.customPackageAddModalData;
  }

  getPackageOptionValuesData(data: any): void {
    this.packages?.map((item: any) => {
      if (item.id == data?.packageData?.id) {
        if (
          data?.packageOptionValues &&
          data?.packageData?.addonOptionValues?.length == 0
        ) {
          const dataObj = data?.packageOptionValues;
          item.optionValues?.map((obj: any) => {
            if (obj.id == data?.packageOptionID) {
              obj.name = dataObj?.title;
              obj.price = Number(dataObj?.regular_price);
              obj.available_stock = dataObj?.stock;
              obj.sku = dataObj?.sku;
              obj.description = dataObj?.description;
              obj.sale_price = Number(dataObj?.sale_price);
              obj.textPercentage = this.taxPercentage;
              obj.total = '';
            }
          });
        }
        if (
          data?.packageData?.addonOptionValues &&
          data?.packageData?.addonName
        ) {
          const addonObj = data?.packageOptionValues;
          item.addonOptionValues?.map((obj: any) => {
            if (obj.id == data?.packageOptionID) {
              obj.name = addonObj?.title;
              obj.price = addonObj?.regular_price;
              obj.available_stock = addonObj?.stock;
              obj.sku = addonObj?.sku;
              obj.description = addonObj?.description;
              obj.sale_price = addonObj?.sale_price;
            }
          });
        }
      }
    });
    this.packageList?.emit(this.packages);
  }

  editPackage(data: any, pindex: any): void {
    this.package.name = data.name;
    this.package.addonName = data.addonName ? data.addonName : '';
    this.package.id = data.id;
    this.packages[pindex].name = this.package.name;
    this.packages[pindex].addonName = this.package.addonName
      ? this.package.addonName
      : '';
    this.packages[pindex].id = this.package.id;
    this.packageList?.emit(this.packages);
    this.isEditPackageName = true;
    this.selectedVariant = data?.variantType;
  }

  deletePackage(index: any): void {
    this.packages.splice(index, 1);
    this.packages?.map((item: any, pindex: any) => {
      item.id = pindex + 1;
    });
    this.packageList?.emit(this.packages);
  }

  getPackageAssignedImg(data: any): void {
    const packIndex = this.assignImgPackageIndex;
    if (!data?.isAddonImg) {
      this.packages[packIndex]?.optionValues?.map((obj: any) => {
        if (obj?.id == data?.optionValueID) {
          obj.img_url = data?.img_url;
          obj.new_img_id = data?.new_img_id;
        }
      });
    } else {
      this.packages[packIndex]?.addonOptionValues?.map((obj: any) => {
        if (obj?.id == data?.optionValueID) {
          obj.img_url = data?.img_url;
          obj.new_img_id = data?.new_img_id;
        }
      });
    }
    if (data?.isVariantImg) {
      this.variantList?.all?.map((obj: any) => {
        if (obj?.id == data?.optionValueID) {
          obj.img_url = data?.img_url;
          obj.new_img_id = data?.new_img_id;
        }
      });
    }
    this.packageList?.emit(this.packages);
  }

  calculateTotalAmount(option: any): number {
    const taxRate = this.taxPercentage;
    const totalPrice = Number(option.sale_price)
      ? Number(option.sale_price)
      : 0;
    const taxAmount = (Number(totalPrice) * Number(taxRate)) / 100;
    const totalAmount = Number(totalPrice) + Number(taxAmount);
    return totalAmount > 0 ? totalAmount : 0.0;
  }
}
