import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { CatalogManagementService } from 'src/app/core/services/catalog-management.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-reject-return-modal',
  templateUrl: './reject-return-modal.component.html',
  styleUrls: ['./reject-return-modal.component.scss'],
})
export class RejectReturnModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  @ViewChild('cancelModal') cancelModal: any;
  @Output() modificationReason = new EventEmitter();

  rejectReasonMaxLength = 500;
  reasonForm = new UntypedFormGroup({
    customReason: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private catalogMngtService: CatalogManagementService,
    private generalService: GeneralService
  ) {
    super();
  }

  get f() {
    return this.reasonForm.controls;
  }

  ngOnInit(): void {}

  save(): void {
    if (this.reasonForm.invalid) {
      this.reasonForm.markAllAsTouched();
      return;
    } else {
      this.modificationReason?.emit(this.reasonForm?.value?.customReason);
      this.cancel();
    }
  }

  cancel(): void {
    this.reasonForm.reset();
    this.cancelModal?.nativeElement?.click();
  }
}
