import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  constructor(private httpClient: HttpClient) {}

  // Get Wallet details
  getWalletDetails(seller_id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/wallet/${seller_id}`
    );
  }

  getPayoutTransactions(seller_id: any, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/payoutList/${seller_id}?${payload}`
    );
  }

  // Add Bank account
  setBankAccount(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/addBankAccount`,
      payload
    );
  }

  removeAccount(payload: any): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.baseApiUrl}vendor/removeBankAccount/${payload?.account_id}`
    );
  }

  getBanks(): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseApiUrl}vendor/bankList`);
  }

  getBankAccountDetails(bankAccountID: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/getBankDetails/${bankAccountID}`
    );
  }

  sendOTPForDeleteAccountVerification(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/deleteAccount/sendOtp/${payload.seller_id}`,
      payload
    );
  }

  verifyOTP(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/deleteAccount/verifyOtp/${payload.seller_id}`,
      payload
    );
  }

  getBeneficiaryList(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/beneficiaryList/${payload?.seller_id}`
    );
  }

  requestPayout(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/requestPayout`,
      payload
    );
  }

  cancelRequestPayout(payout_id: any): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.baseApiUrl}vendor/cancelPayout/${payout_id}`
    );
  }

  sendPayoutOTP(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/requestPayout/sendOtp/${payload.seller_id}`,
      payload
    );
  }

  payoutOTPVerification(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/requestPayout/verifyOtp/${payload.seller_id}`,
      payload
    );
  }
}
