import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-request-admin-to-edit-modal',
  templateUrl: './request-admin-to-edit-modal.component.html',
})
export class RequestAdminToEditModalComponent implements OnInit {
  @ViewChild('closeModal') closeModal: any;
  @Output() openTickeModal = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  openTicketModal(): void {
    this.closeReqModal();
    this.openTickeModal.emit();
  }

  closeReqModal(): void {
    this.closeModal?.nativeElement.click();
  }
}
