import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '@environments/environment';

import { LoginGuard } from './core/guards/login.guard';
import { UserGuard } from './core/guards/user.guard';
import { VerifyEmailResolver } from './core/resolver/verify-email.resolver';
import { InvoicePrintComponent } from './shared/components/printables/invoice-print/invoice-print.component';
import { ShippingPrintComponent } from './shared/components/printables/shipping-print/shipping-print.component';
import { ResetPasswordComponent } from './shared/components/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivateChild: [UserGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./modules/register/register.module').then(
        (m) => m.RegisterModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'shipment-print/:id',
    component: ShippingPrintComponent,
  },
  {
    path: 'invoice-print/:id',
    component: InvoicePrintComponent,
  },
  {
    path: 'reset/:email',
    component: ResetPasswordComponent,
    data: {
      detailApi: `${environment.baseApiUrl}vendor/verify`,
      redirectTo: '/',
    },
    resolve: {
      detail: VerifyEmailResolver,
    },
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
