import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { WalletService } from 'src/app/core/services/wallet.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-proceed-with-payout-modal',
  templateUrl: './proceed-with-payout-modal.component.html',
})
export class ProceedWithPayoutModalComponent
  extends SubscriptionDisposer
  implements OnInit, OnChanges
{
  @Input() bankID: any;
  @Input() amount: any;
  @Output() openOTPVerifyModal = new EventEmitter();
  @ViewChild('closeModal') closeModal: any;

  bankDetails: any;

  constructor(
    private walletService: WalletService,
    private generalService: GeneralService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.bankID) {
      this.getBankAccountDetails();
    }
  }

  getBankAccountDetails(): void {
    this.walletService
      .getBankAccountDetails(this.bankID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          if (response) {
            this.bankDetails = response?.data;
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  continue(): void {
    this.closeModal.nativeElement?.click();
    this.openOTPVerifyModal?.emit();
  }
}
