import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import { catchError, map } from 'rxjs/operators';

import { GeneralService } from '@core/services/general.service';

@Injectable({
  providedIn: 'root',
})
export class VerifyEmailResolver<T> implements Resolve<T> {
  constructor(
    private router: Router,
    private http: HttpClient,
    private generalService: GeneralService
  ) {}

  resolve(route: ActivatedRouteSnapshot): any {
    let params = '';
    const api = route.data.detailApi;
    if (route.paramMap.get('email')) {
      params = route.paramMap.get('email');
    }
    return this.http
      .get(`${api}/${params}`)
      .pipe(
        map((res: any) => {
          console.log(res);
          if (res?.data) {
            this.generalService.displaySuccess(res?.message);
            return res.data;
          } else {
            this.generalService.displayError(res?.message);

            this.router.navigate(['/']);
            return null;
          }
        })
      )
      .pipe(
        catchError((error) => {
          this.generalService.displayError(error.error.message);
          this.router.navigate(['/login']);
          return error;
        })
      );
  }
}
