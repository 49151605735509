import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private httpClient: HttpClient) {}

  // analytics
  getAnalyticsDetails(vendorID: string, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/analytics/${vendorID}?${payload}`
    );
  }

  getLatestOrders(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/orders/${vendorID}?${payload}`
    );
  }

  getSalesAnalytics(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/salesanalytics/${vendorID}?${payload}`
    );
  }

  getTopSellingSKUs(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/analyticstopsellingsku/${vendorID}?${payload}`
    );
  }

  // reports
  getReportsDetails(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/reports/${vendorID}`
    );
  }

  getTotalEarning(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/reports/total_earning/${vendorID}`
    );
  }

  getEarningReportDataForReports(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/reports/earning_reports/${vendorID}`
    );
  }

  getCustomReports(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/reports/earning_reports/${vendorID}`
    );
  }

  getCustomReportFields(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/reports/earning_reports/${vendorID}`
    );
  }

  // crm
  getCRMDetails(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/crm/${vendorID}`
    );
  }

  getInvoiceList(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/invoice/${vendorID}?${payload}`
    );
  }

  getStatastics(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/crm/statistics/${vendorID}?${payload}`
    );
  }

  //common
  getPopularProducts(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/analyticspopularproduct/${vendorID}`
    );
  }

  getOrders(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/orders/${vendorID}?${payload}`
    );
  }

  getEarningReportData(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/crm/earning_reports/${vendorID}`
    );
  }

  //custom report
  getreportTemplateFields(payload: any, vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/customReportTemplateFields/${payload.template_id}/${vendorID}`
    );
  }

  setReportTemplate(payload: any, vendorID: string): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/createReportTemplate/${vendorID}`,
      payload
    );
  }

  getCustomReportTemplats(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/customReportTemplates`
    );
  }

  getCustomReport(
    payload: any,
    template_id: string,
    vendorID: string
  ): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/customReports/${template_id}/${vendorID}?${payload}`
    );
  }
}
