import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { takeUntil } from 'rxjs';
import { CatalogManagementService } from 'src/app/core/services/catalog-management.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-commit-changes-modal',
  templateUrl: './commit-changes-modal.component.html',
})
export class CommitChangesModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  @ViewChild('cancelModal') cancelModal: any;
  @Output() modificationReason = new EventEmitter();

  reasonForm = new UntypedFormGroup({
    reason: new UntypedFormControl('', Validators.required),
    customReason: new UntypedFormControl(''),
  });

  reasonList: any = [];
  isCustomReason: any = false;

  constructor(
    private catalogMngtService: CatalogManagementService,
    private generalService: GeneralService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getModificationReason();
  }

  getModificationReason(): void {
    this.catalogMngtService
      .getModificationReason()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          if (response) {
            this.reasonList = response?.data;
            this.reasonList?.push({
              id: 'custom',
              text: '+ Custom Reason',
            });
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  save(): void {
    if (this.reasonForm.invalid) {
      this.reasonForm.markAllAsTouched();
      return;
    } else if (!this.reasonForm.value.customReason && this.isCustomReason) {
      return;
    } else {
      this.modificationReason?.emit(
        this.isCustomReason
          ? this.reasonForm?.value?.customReason
          : this.reasonForm?.value?.reason
      );
      this.cancel();
    }
  }

  cancel(): void {
    this.reasonForm.reset();
    this.cancelModal?.nativeElement?.click();
    this.isCustomReason = false;
  }

  get f() {
    return this.reasonForm.controls;
  }

  changeReason(data: any): void {
    if (data == 'custom') {
      this.isCustomReason = true;
    }
  }

  resetCustomReason(): void {
    this.reasonForm.patchValue({
      customReason: '',
      reason: '',
    });
    this.isCustomReason = false;
  }
}
