import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { takeUntil } from 'rxjs';
import { AccountSettingsService } from 'src/app/core/services/account-settings.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-de-activate-modal',
  templateUrl: './de-activate-modal.component.html',
})
export class DeActivateModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  @ViewChild('closeMdl') closeMdl: any;
  @Output() closeDeAcctModal = new EventEmitter();

  deActivateAccount = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private generalService: GeneralService,
    private accountSettingService: AccountSettingsService,
    private storageService: StorageService,
    private router: Router
  ) {
    super();
  }
  showPwd: any = false;

  ngOnInit(): void {}

  get f() {
    return this.deActivateAccount.controls;
  }

  save(): void {
    if (this.deActivateAccount.invalid) {
      this.deActivateAccount.markAllAsTouched();
      return;
    } else {
      const payload = {
        is_account_active: 0,
        password: this.deActivateAccount.value.password,
        seller_id: this.storageService.getCookie('userID'),
      };

      this.accountSettingService
        .deActivateAccount(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res) {
              this.generalService.displaySuccess(res?.message);
              this.closeModal();
              this.reset();
              this.generalService.logout();
              this.router.navigate(['/landing']);
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  reset(): void {
    this.deActivateAccount.reset();
  }

  closeModal(): void {
    this.closeDeAcctModal.emit();
    this.closeMdl.nativeElement.click();
  }
}
