<div class="modal-content">
  <div class="modal-body">
    <div class="fc-order-info">
      <div class="fc-order-header">
        <div class="name-info">
          <h4>Order #12345</h4>
          <p class="date">3 Jul - 4 Jul</p>
        </div>
        <div class="action-link">
          <a href="javascript: void(0)"
            ><img src="assets/images/share-order.svg" alt="share"
          /></a>
          <a href="javascript: void(0)"
            ><img src="assets/images/close-order.svg" alt="close"
          /></a>
        </div>
      </div>
      <div class="fc-order-body">
        <div class="order-img">
          <img src="assets/images/img.jpg" alt="img" />
        </div>
        <div class="order-avatar">
          <div class="img-avatar">
            <img src="assets/images/user-icon-vector2.svg" alt="user-icon" />
          </div>
          <h6>Your Company Name</h6>
        </div>
        <div class="order-sku">
          <div class="t-badge">SKU</div>
          #123456
        </div>
      </div>
    </div>
  </div>
</div>
