<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title text-uppercase">PAYOUT SUCCESS</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <img src="assets/images/close-outline.svg" alt="close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="request-payout-form reference-payout">
      <div class="copy-selection">
        <label for="">Reference Number</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="referenceNumber"
          #userinput
        />
        <a href="javascript: void(0)" (click)="copyInputMessage(userinput)">
          <img src="assets/images/copy-reference.svg" alt="copy-reference" />
        </a>
      </div>
      <p>
        Please wait for the funds to be credited to your account within 1-3
        working days. If any problem occurs, please feel free to contact +971 4
        123 4567 or email us at support@mfz.ae.
      </p>
      <div class="bottom-btnrow">
        <button class="btn t-grey-btn" data-dismiss="modal" aria-label="Close">
          Close
          <img
            src="assets/images/close-outline-2.svg"
            alt="close"
            class="icon"
          />
        </button>
      </div>
    </div>
  </div>
</div>
