import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, OnChanges, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Select2OptionData } from 'ng-select2';
import { takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { WalletService } from 'src/app/core/services/wallet.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-request-payout-modal',
  templateUrl: './request-payout-modal.component.html',
})
export class RequestPayoutModalComponent
  extends SubscriptionDisposer
  implements OnInit, OnChanges
{
  bankID: any;
  balance: number;
  amount: any = '';
  beneficiaryList: Array<Select2OptionData> = [];
  bankDetails: any;
  beneficiary: any;

  payoutForm = new UntypedFormGroup({
    beneficiary: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private storageService: StorageService,
    public generalService: GeneralService,
    private walletService: WalletService,
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: any
  ) {
    super();
    this.bankID = data.bankID;
    if (data.balance) {
      this.balance = data?.balance;
    }
  }

  ngOnInit(): void {
    if (this.bankID) {
      this.getBankDetails();
      this.getBeneficiaryList();
    }
    console.log(this.bankID);
    this.payoutForm.controls['beneficiary'].valueChanges.subscribe((item) => {
      this.bankID = item;
      this.getBankDetails();
    });
  }

  ngOnChanges(): void {
    if (this.bankID) {
      this.getBankDetails();
      this.getBeneficiaryList();
    }
  }

  reset(): void {
    this.payoutForm.reset();
    this.close();
  }

  getBankDetails(): void {
    this.walletService
      .getBankAccountDetails(this.bankID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          if (response) {
            this.bankDetails = response?.data;
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  getBeneficiaryList(): void {
    const payload = {
      seller_id: this.storageService.getCookie('userID'),
    };
    this.walletService
      .getBeneficiaryList(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          if (response) {
            this.beneficiaryList = response?.data.map((beneficiary) => ({
              id: beneficiary?._id,
              text: beneficiary.text,
            }));
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  requestPayout(): void {
    if (this.payoutForm.invalid) {
      this.payoutForm.markAllAsTouched();
      return;
    } else {
      const payload = {
        seller: this.storageService.getCookie('userID'),
        bank_account: this.bankID,
        beneficiary: this.payoutForm?.value?.beneficiary,
      };
      this.walletService
        .requestPayout(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response.success) {
              this.generalService.displaySuccess(response?.message);
              this.close(true);
            } else {
              this.generalService.displayError(response?.message);
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  get f() {
    return this.payoutForm.controls;
  }

  close(data?) {
    this.dialogRef.close(data);
  }
}
