import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { CredentialService } from '../services/credential.service';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsInitializer {
  constructor(
    private http: HttpClient,
    private localStorage: StorageService,
    private credential: CredentialService
  ) {
    this.Init();
  }

  async Init(): Promise<boolean> {
    if (this.localStorage.getCookie(`AccessToken`)) {
      const API = `${environment.baseApiUrl}vendor/getSiteSettings`;
      return new Promise((resolve) => {
        this.http.get(API).subscribe((response: any) => {
          if (response && response.success) {
            this.credential.setUserDetails(response?.data);
            resolve(true);
          } else {
            resolve(true);
          }
        });
      });
    } else {
      return Promise.resolve(true);
    }
  }
}
