import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-request-payout-sent-modal',
  templateUrl: './request-payout-sent-modal.component.html',
})
export class RequestPayoutSentModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  closeSentModal(): void {
    this.closeModal?.emit(true);
  }
}
