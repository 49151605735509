import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { OrderManagementService } from 'src/app/core/services/order-management.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-shipping-print',
  templateUrl: './shipping-print.component.html',
})
export class ShippingPrintComponent
  extends SubscriptionDisposer
  implements OnInit
{
  @Input() isPrint: any;

  shipmentDetails: any = [];
  shipmentID: any = '';
  billingAddress: any;
  shippingAddress: any;
  items: any = [];
  finalSubtotal: any = 0;
  finalGrandTotal: any = 0;
  finalDiscount: any = 0;
  finalTax: any = 0;
  lastCardDigit: any;

  constructor(
    public generalService: GeneralService,
    private route: ActivatedRoute,
    private orderMngtService: OrderManagementService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    const pageUrl = this.router.url;
    if (pageUrl) {
      const urlArr = pageUrl?.split('/');
      if (urlArr?.length > 0) {
        if (this.isPrint) {
          const url = urlArr[urlArr?.length - 1];
          const lastPath = url?.split('=');
          this.shipmentID = lastPath?.length
            ? lastPath[lastPath?.length - 1]
            : '';
        } else {
          this.shipmentID = urlArr[urlArr?.length - 1];
        }
        if (this.shipmentID) {
          this.getShippingPrintDetails();
        }
      }
    }
  }

  getShippingPrintDetails() {
    this.orderMngtService
      .getShipmentDetails(this.shipmentID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response: any) => {
          if (response && response.data) {
            this.shipmentDetails = response.data;
            const cardNumber = response.data?.card;
            this.lastCardDigit = cardNumber
              ? cardNumber.substr(cardNumber.length - 4)
              : '';
            if (this.shipmentDetails?.product?.length > 0) {
              this.items = [...this.shipmentDetails?.product];
            }
            if (this.items?.length > 0) {
              this.shippingAddress = this.items[0]?.selected_address;
              this.finalSubtotal = this.shipmentDetails?.subtotal;
              this.finalDiscount = this.shipmentDetails?.discount;
              this.finalTax = this.shipmentDetails?.tax;
              this.finalGrandTotal = this?.shipmentDetails?.total;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
