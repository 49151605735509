import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  constructor(private httpClient: HttpClient) {}

  // Coupons
  getCoupons(vendoId: string, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/getCouponList/${vendoId}?${payload}`
    );
  }

  setCoupon(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/addCoupon`,
      payload
    );
  }
  updateCoupon(payload: any, couponId: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/editCoupon/${couponId}`,
      payload
    );
  }

  getCouponDetails(couponID: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/getCoupon/${couponID}`
    );
  }

  deleteCoupon(couponID: any): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.baseApiUrl}vendor/deleteCoupon/${couponID}`
    );
  }

  enableDisableCoupons(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkCouponStatusUpdate`,
      payload
    );
  }

  bulkDeleteCoupons(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkCouponDelete `,
      payload
    );
  }

  getCategoryWiseSKUs(vendorID: string, payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/categorywiseskus/${vendorID}`,
      payload
    );
  }

  //puechases
  getAdvPurchasList(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/`,
      payload
    );
  }
}
