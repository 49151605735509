import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import * as moment from 'moment';
import { Select2OptionData } from 'ng-select2';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
  selector: 'app-reports-filter',
  templateUrl: './reports-filter.component.html',
})
export class ReportsFilterComponent implements OnInit {
  filterForm!: UntypedFormGroup;
  statusList: Array<any> = [
    {
      id: '0',
      text: 'Pending',
      checked: false,
    },
    {
      id: '1',
      text: 'Packed',
      checked: false,
    },
    {
      id: '2',
      text: 'Shipped',
      checked: false,
    },
    {
      id: '3',
      text: 'Delivered',
      checked: false,
    },
    {
      id: '4',
      text: 'Cancelled',
      checked: false,
    },
    {
      id: '5',
      text: 'Failed',
      checked: false,
    },
  ];
  periodList: Array<Select2OptionData> = [
    {
      id: '0',
      text: 'Daily',
    },
    {
      id: '1',
      text: 'Weekly',
    },
    {
      id: '2',
      text: 'Monthly',
    },
  ];
  todate = '';
  fromDate = '';
  today: any;

  @Output() applyFilter = new EventEmitter();

  constructor(public generalService: GeneralService) {}

  ngOnInit(): void {
    this.prepareForm();
    this.toggle();
  }

  prepareForm(): void {
    this.filterForm = new UntypedFormGroup({
      period: new UntypedFormControl(''),
      start_date: new UntypedFormControl(''),
      end_date: new UntypedFormControl(''),
    });
  }

  toggle(): void {
    jQuery('.filter-record-div .filter-btn').on('click', function () {
      jQuery(this).next('.filter-content').slideToggle();
      if (jQuery(this).next('.filter-content').is(':visible')) {
        jQuery(this).toggleClass('active');
      }
      // return false;
    });
  }

  changeDate(id: any): void {
    if (id == 1) {
      this.fromDate = this.filterForm?.value?.start_date;
    } else {
      this.todate = this.filterForm?.value?.end_date;
    }
  }

  onPeriodChanged(event: any): void {
    this.today = moment();
    const c = this.today.format('YYYY-MM-DD');
    const y = moment().subtract(1, 'month').format('YYYY-MM-DD');

    let startDay;
    let endDay;

    if (event == 0) {
      startDay = c;
      endDay = c;
    } else if (event == 1) {
      const startOfWeek = this.today
        .clone()
        .startOf('week')
        .format('YYYY-MM-DD');
      const endOfWeek = this.today.clone().endOf('week').format('YYYY-MM-DD');
      startDay = startOfWeek;
      endDay = endOfWeek;
    } else {
      startDay = y;
      endDay = c;
    }
    this.filterForm.controls['start_date'].patchValue(startDay);
    this.filterForm.controls['end_date'].patchValue(endDay);
  }

  onSelectAll(flag: boolean): void {
    this.statusList = this.statusList.map((m) => ({
      ...m,
      checked: flag,
    }));
  }

  resetForm(): void {
    this.filterForm.reset();
    this.todate = '';
    this.fromDate = '';
    this.onSelectAll(false);
    this.applyFilter.emit();
  }

  apply(): void {
    const filterValues = this.filterForm.value;
    delete filterValues.period;
    const status = this.statusList
      .filter((f) => f.checked)
      .map((m) => m.id)
      .join(',');
    if (Object.keys(filterValues).some((e) => filterValues[e]) || status) {
      const obj = {
        ...filterValues,
        status,
      };
      this.applyFilter.emit(obj);
    } else {
      this.applyFilter.emit();
    }
  }
}
