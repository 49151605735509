import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountSettingsService {
  constructor(private httpClient: HttpClient) {}

  // Vendor Details Profile, company and store
  getVendorDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/details/${payload.seller_id}`
    );
  }

  // Vendor Identity details
  getUserIdentityDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/identity/${payload.sellerId}`
    );
  }

  getVendorAccountDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/accountdetails/${payload.seller_id}`
    );
  }

  setVendorDetails(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/update/${payload.seller_id}`,
      payload
    );
  }

  // Profile
  editEmail(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/updateEmail/sendOtp/${payload.seller_id}`,
      payload
    );
  }

  verifyEmail(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/verifyEmail`,
      payload
    );
  }

  editPassword(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/updatePassword/${payload.seller_id}`,
      payload
    );
  }

  deActivateAccount(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/deactivate/${payload.seller_id}`,
      payload
    );
  }

  getCountryList(): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseApiUrl}vendor/country`);
  }

  getLanguages(): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseApiUrl}vendor/language`);
  }

  getCityList(): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseApiUrl}vendor/citylist`);
  }

  editMobileNumber(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/updateMobile/sendOtp/${payload.seller_id}`,
      payload
    );
  }

  verifyMobileNumber(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/verifyAndUpdateMobile/${payload.seller_id}`,
      payload
    );
  }

  // Teams
  getTeamsList(sellerId: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/teamMemberList/${sellerId}?${payload}`
    );
  }

  setTeammate(payload: any): Observable<any> {
    if (payload.teammateId) {
      return this.httpClient.put<any>(
        `${environment.baseApiUrl}vendor/teamMember/${payload.teammateId}`,
        payload
      );
    } else {
      return this.httpClient.post<any>(
        `${environment.baseApiUrl}vendor/teamMember`,
        payload
      );
    }
  }

  setTeamRegistration(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/updateTeammember`,
      payload
    );
  }

  getTeamDetails(teammateId: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/teamMember/${teammateId}`
    );
  }

  deleteTeammate(teammateId: any): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.baseApiUrl}vendor/teamMember/${teammateId}`
    );
  }

  uploadTeamProfileImage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/teamMember/profile`,
      payload
    );
  }

  sendTeamMemberOTP(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/teammember/sendOtp`,
      payload
    );
  }

  verifyTeamMobileNumber(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/teammember/verifyOtp`,
      payload
    );
  }

  checkAccountStatus(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/team/checkAccountStatus`,
      payload
    );
  }

  // Role
  getRolesList(sellerId: any, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/roles/${sellerId}?${payload}`
    );
  }

  setRole(payload: any): Observable<any> {
    if (payload.role_id) {
      return this.httpClient.put<any>(
        `${environment.baseApiUrl}vendor/role/${payload.role_id}`,
        payload
      );
    } else {
      return this.httpClient.post<any>(
        `${environment.baseApiUrl}vendor/addRole`,
        payload
      );
    }
  }

  bulkUpdateRoleStatus(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkUpdateRoleStatus`,
      payload
    );
  }

  bulkDeleteRole(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkDeleteRole`,
      payload
    );
  }

  getRoleDetails(roleId: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/roleDetails/${roleId}`
    );
  }

  checkRole(roleId: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/checkRole/${roleId}`
    );
  }

  changeAccountStatusnge(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}team/UpdateAccountStatus`,
      payload
    );
  }
}
