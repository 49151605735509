<div
  class="custom-dialog-modal modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Add Custom Information</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        #closeMdl
        aria-label="Close"
        (click)="close()"
      >
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body" [formGroup]="customInfo">
      <div class="edit-form-row package-form-row">
        <div class="form-group">
          <div class="left-title">
            <label>Label<span class="text-danger">*</span></label>
          </div>
          <div class="right-field">
            <input
              type="text"
              class="form-control"
              placeholder="Enter form label"
              formControlName="label"
              maxlength="50"
            />
            <div
              *ngIf="f['label'].touched && f['label'].invalid"
              class="cross-validation-error-message"
            >
              <div
                *ngIf="f['label'].errors?.['required']"
                class="required-note text-danger"
              >
                Label is required.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="left-title">
            <label>Title<span class="text-danger">*</span></label>
          </div>
          <div class="right-field">
            <input
              type="text"
              class="form-control"
              placeholder="Enter title"
              formControlName="title"
              maxlength="100"
            />
            <div
              *ngIf="f['title'].touched && f['title'].invalid"
              class="cross-validation-error-message"
            >
              <div
                *ngIf="f['title'].errors?.['required']"
                class="required-note text-danger"
              >
                Title is required.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="left-title">
            <label>Type<span class="text-danger">*</span></label>
          </div>
          <div class="right-field">
            <div class="type-info-control">
              <button
                class="btn"
                [ngClass]="{ active: f['type'].value === 'single' }"
                (click)="onTypeChange('single')"
              >
                <div class="icon">
                  <img
                    src="assets/images/single-line-t.svg"
                    alt="single-line"
                  />
                </div>
                Single line
              </button>
              <button
                class="btn"
                [ngClass]="{ active: f['type'].value === 'multi' }"
                (click)="onTypeChange('multi')"
              >
                <div class="icon">
                  <img src="assets/images/multi-line-i.svg" alt="multi-line" />
                </div>
                Multi line
              </button>
            </div>
            <div
              *ngIf="f['type'].touched && f['type'].invalid"
              class="cross-validation-error-message"
            >
              <div
                *ngIf="f['type'].errors?.['required']"
                class="required-note text-danger"
              >
                Type is required.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <div class="bottom-btnrow">
        <button class="btn t-grey-btn-outline" (click)="reset()">
          <img src="assets/images/reset-icon.svg" alt="reset" /> RESET
        </button>
        <button class="btn t-primary-btn" (click)="save()">
          <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
        </button>
      </div>
    </div>
  </div>
</div>
