import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Select2OptionData } from 'ng-select2';
import { takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SupportService } from 'src/app/core/services/support.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-add-new-ticket-modal',
  templateUrl: './add-new-ticket-modal.component.html',
  styleUrls: ['./add-new-ticket-modal.component.scss'],
})
export class AddNewTicketModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  config!: AngularEditorConfig;

  couponID: any = 0;

  allOrderList: Array<Select2OptionData> = [];
  allItemList: any = [];
  itemObj = {};
  items: any = [];
  ticketData = new UntypedFormGroup({
    subject: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required]),
    order: new UntypedFormControl('', [Validators.required]),
    item: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
  });

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      couponID: any;
    },
    private generalService: GeneralService,
    private supportService: SupportService,
    private storageService: StorageService
  ) {
    super();

    if (data?.couponID) {
      this.couponID = data.couponID;
    }
  }

  get f() {
    return this.ticketData.controls;
  }

  ngOnInit(): void {
    this.config = {
      ...this.generalService.getEditorConfig(),
      toolbarPosition: 'bottom',
    };
    this.getOrderReferenceList();
    this.ticketData.get('order').valueChanges.subscribe((value) => {
      this.getItemReferenceList(value);
    });
  }

  getOrderReferenceList(): void {
    const payload = {
      seller_id: this.storageService.getCookie('userID'),
    };
    this.supportService
      .getOrderReferenceList(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response && response.data) {
          this.allOrderList = response?.data;
        }
      });
  }

  getItemReferenceList(orderID: any): void {
    this.allItemList = [];
    if (orderID) {
      this.ticketData.get('item').enable();
    } else {
      this.ticketData.get('item').disable();
    }
    const payload = {
      seller_id: this.storageService.getCookie('userID'),
      order_id: orderID ? orderID : '',
    };
    this.supportService
      .getOrderWiseItemReferenceList(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response && response.data) {
          this.items = response?.data;
          if (this.items?.length > 0) {
            this.allItemList = this.items?.map((item: any) => {
              item.text = item.variants?.name
                ? item.name + ' (' + item.variants?.name + ')'
                : item?.name;
              return {
                text: item.variants?.name
                  ? item.name + ' (' + item.variants?.name + ')'
                  : item?.name,
                id: item.variants?.name
                  ? item.name + ' (' + item.variants?.name + ')'
                  : item?.name,
              };
            });
          }
        }
      });
  }

  getSelectedItem(data: any): void {
    this.itemObj = this.items?.filter((item: any) => {
      if (item.text == data) {
        return item;
      }
    })?.[0];
  }

  save(): void {
    if (this.ticketData.invalid) {
      this.ticketData.markAllAsTouched();
    } else {
      const payload = {
        subject: this.ticketData.value?.subject,
        description: this.ticketData.value?.description,
        order: this.ticketData.value?.order,
        seller: this.storageService.getCookie('userID'),
        item: this.itemObj ? this.itemObj : {},
        status: 0, // 0 => open, 1=> close
        tickets_by: 1, //0 => customer to seller, 1=> seller to admin
      };
      this.supportService.addTicket(payload).subscribe(
        () => {
          this.close({ onSaveClose: true });
        },
        (err) => {
          this.generalService.displayError(err.error.message);
        }
      );
    }
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }
}
