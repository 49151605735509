<div
  class="custom-dialog-modal modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">New Ticket</h4>
      <button type="button" class="close" (click)="close()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <div class="add-new-ticket-block" [formGroup]="ticketData">
        <div class="form-group">
          <label for="">Subject</label>
          <input
            type="text"
            class="form-control"
            formControlName="subject"
            placeholder=""
            value="Request Admin to edit product - SKU #123"
          />
          <div
            *ngIf="f['subject'].invalid && f['subject'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['subject'].errors && f['subject'].errors['required']"
              class="required-note text-danger"
            >
              Subject is required.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="">Where do you need help?</label>
          <ng-select2
            (valueChanged)="getTableFields($event)"
            *ngIf="modules.length > 0"
            formControlName="module"
            name=""
            id="moduleID"
            class="select2-control"
            [data]="modules"
            [placeholder]="'Select from list'"
          >
          </ng-select2>
          <div
            *ngIf="f['module'].invalid && f['module'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['module'].errors && f['module'].errors['required']"
              class="required-note text-danger"
            >
              Please select module
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="ticketData.value.module">
          <label for="">Select fields to edit</label>
          <ng-select2
            (valueChanged)="selectFields($event)"
            class="select2-control"
            [data]="moduleFields"
            [placeholder]="'Select field to edit'"
          >
          </ng-select2>
          <div class="selected-items-tag">
            <ul>
              <li *ngFor="let f of fields; let index = index">
                {{ f }}
                <a href="javascript: void(0)" (click)="removeField(index)"
                  ><img src="assets/images/close-outline-black.svg" alt="trash"
                /></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group">
          <label for="">Tell us how each field should be configured</label>
          <textarea
            formControlName="description"
            name=""
            id=""
            cols="30"
            rows="10"
            class="form-control"
            placeholder="Placeholder"
          ></textarea>
          <div
            *ngIf="f['description'].invalid && f['description'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="
                f['description'].errors && f['description'].errors['required']
              "
              class="required-note text-danger"
            >
              Description is required.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="bottom-btnrow">
        <button class="btn t-primary-btn" (click)="save()">
          <img src="assets/images/paper-plane-outline.svg" alt="send" /> Send
        </button>
      </div>
    </div>
  </div>
</div>
