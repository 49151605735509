import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SupportService } from 'src/app/core/services/support.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-ticket-chat-configuration-modal',
  templateUrl: './ticket-chat-configuration-modal.component.html',
})
export class TicketChatConfigurationModalComponent
  extends SubscriptionDisposer
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() chatModalObject: any;
  @Output() imgUploaded = new EventEmitter();
  @ViewChild('replyDiv') replyDiv: ElementRef;
  msgList: Array<any> = [];
  vendorId = this.storageService.getCookie('userID');

  config!: AngularEditorConfig;
  payload: any = {
    to_user: '',
    from_vendor: this.vendorId,
    from_user: '',
    to_vendor: '',
    from_admin: '',
    to_admin: '',
    title: '',
    message: '',
  };
  fromChatList: any = [];
  toChatList: any = [];
  ticketFormData: FormData = new FormData();
  ticketToUpload: any[] = [];
  ticketToSend: any;
  ticketImageUrl: any;
  ticketeUploadInput: any;
  ticketId: string;

  replyData = new UntypedFormGroup({
    msg: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private storageService: StorageService,
    private supportService: SupportService,
    private generalService: GeneralService
  ) {
    super();
  }

  get f() {
    return this.replyData.controls;
  }

  ngOnInit(): void {
    this.config = {
      ...this.generalService.getEditorConfig(),
      toolbarPosition: 'bottom',
      height: '10rem',
      toolbarHiddenButtons: [
        [
          'strikeThrough',
          'subscript',
          'superscript',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'indent',
          'outdent',
          'fontName',
          'insertUnorderedList',
          'insertOrderedList',
          'heading',
        ],
        [
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'customClasses',
          'fontSize',
          'textColor',
          'backgroundColor',
          'toggleEditorMode',
        ],
      ],
    };

    this.supportService
      .readMessages(this.chatModalObject._id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          if (res && res?.data) {
            console.log('Message read successfully');
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chatModalObject'] && changes['chatModalObject'].currentValue) {
      this.payload.ticket_id = this.chatModalObject?._id || '';
      this.payload.to_admin = this.chatModalObject?.admin || '';
      this.getMessages();
    }
  }

  ngAfterViewInit() {
    console.log(this.replyDiv);
    if (this.replyDiv) {
      setTimeout(() => {
        this.replyDiv.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 150);
    }
  }

  getMessages(): void {
    this.ticketId = this.chatModalObject._id;
    this.supportService
      .getMessages(this.chatModalObject._id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          if (res && res?.data) {
            this.msgList = res?.data || [];
            this.msgList?.map((item: any) => {
              if (item?.file_name) {
                const imgArr = item?.file_name?.split('.');
                item.file_type = imgArr[1];
              }
            });
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  sendMessage(): void {
    if (this.replyData.invalid) {
      this.replyData.markAllAsTouched();
      return;
    }
    const payload = {
      message: this.replyData.value.msg,
      ticket_id: this.chatModalObject?._id,
      from_vendor: this.storageService?.getCookie('userID'),
    };
    this.supportService
      .sendMessage(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        () => {
          this.getMessages();
          this.resetForm();
        },
        () => {}
      );
  }

  resetForm(): void {
    this.replyData.reset();
  }

  uploadTicketFile(event: any, ticketID: any): void {
    const file: File = event.target.files[0];
    this.generalService
      .confirmationDialog('Are You Sure?', `You want to send this file`)
      .then((result: any) => {
        if (result.isConfirmed) {
          if (file) {
            this.ticketFormData = new FormData();
            this.ticketFormData.append('ticketfiles', file);
            this.ticketToSend = event.target.files[0];
            if (this.ticketToSend) {
              this.ticketFormData.append('ticket_id', ticketID);
              this.supportService
                .uploadTicketFile(this.ticketFormData)
                .pipe(takeUntil(this.destroyed$))
                .subscribe({
                  next: (res) => {
                    if (res) {
                      this.imgUploaded.emit();
                      this.generalService.displaySuccess(res?.message);
                      this.getMessages();
                      this.resetForm();
                    }
                  },
                  error: (error) => {
                    this.generalService.displayError(error?.error?.message);
                  },
                });
            }
          } else {
            this.ticketeUploadInput.nativeElement.value = '';
            this.generalService.displayError('Please select file');
          }
        }
      });
  }
}
