import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyDateFormat',
})
export class OnlyDateFormatPipe implements PipeTransform {
  transform(value: string): unknown {
    const t = new Date(value);
    const date = ('0' + t.getDate()).slice(-2);
    const month = ('0' + (t.getMonth() + 1)).slice(-2);
    const year = ('0' + t.getFullYear()).slice(-2);
    const hours = ('0' + t.getHours()).slice(-2);
    const minutes = ('0' + t.getMinutes()).slice(-2);
    const seconds = ('0' + t.getSeconds()).slice(-2);
    const time = `${date}/${month}/${year}`;
    return time;
  }
}
