import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

import { Select2OptionData } from 'ng-select2';
import { takeUntil } from 'rxjs';
import { CatalogManagementService } from 'src/app/core/services/catalog-management.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';
import { AirDatepickerOptions, AirDatepickerPosition } from 'air-datepicker';
import { formatDate } from '@angular/common';
import { start } from 'repl';

@Component({
  selector: 'app-crosssell-upsell-product-selection-modal',
  templateUrl: './crosssell-upsell-product-selection-modal.component.html',
})
export class CrosssellUpsellProductSelectionModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  specificItem: any;
  isCrosssellUpsellAdded: any;
  isService: any;
  tags: any;
  triggerID: any;

  productSKUs: Array<Select2OptionData> = [];
  serviceSKUs: Array<Select2OptionData> = [];
  copyProductSKUs: any = [];
  copyServiceSKUs: any = [];
  copyProducts: Array<Select2OptionData> = [];
  copyServices: Array<Select2OptionData> = [];
  products: any = [];
  selectedItem: any;
  services: any = [];
  minDate: any;
  productForm = new FormGroup({
    sku: new UntypedFormControl('', [Validators.required]),
    startDate: new UntypedFormControl('', [Validators.required]),
    startTime: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required]),
    endTime: new UntypedFormControl('', [Validators.required]),
  });

  startMinTime = '00:00';
  endMinTime = '00:00';

  fromDate: string;
  todate: string;
  startDateOptions: AirDatepickerOptions = {
    dateFormat: 'dd/MM/yyyy',
    position: 'top left',
  };
  endDateOptions: AirDatepickerOptions = {
    dateFormat: 'dd/MM/yyyy',
    position: 'top left',
  };

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      specificItem: any;
      isCrosssellUpsellAdded: any;
      isService: any;
      tags: any;
      triggerID: any;
    },
    private catalogMngtService: CatalogManagementService,

    private generalService: GeneralService,
    private storageService: StorageService
  ) {
    super();

    if (data?.specificItem) {
      this.specificItem = data.specificItem;
    }

    if (data?.isCrosssellUpsellAdded) {
      this.isCrosssellUpsellAdded = data.isCrosssellUpsellAdded;
    }
    if (data?.isService) {
      this.isService = data.isService;
    }
    if (data?.tags) {
      this.tags = data.tags;
    }
    if (data?.triggerID) {
      this.triggerID = data.triggerID;
    }
  }

  ngOnInit(): void {
    if (this.isService) {
      this.getServiceSKUs();
    } else {
      this.getProductSKUs();
    }

    this.productForm.controls.startDate.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.setStartMinTime(value);
      });
    this.productForm.controls.endDate.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.setEndMinTime(value);
      });

    this.setStartDateOption(moment().toDate(), false); //start date
    this.setEndDateOption(moment().toDate(), false); //end date
  }

  getProductSKUs(): void {
    const payload = {
      vendorID: this.storageService.getCookie('userID'),
    };
    this.catalogMngtService
      .getAllProducts(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response && response?.data) {
          this.products = response?.data;
          this.copyProducts = this.products;
        }
        this.getCategoryWiseSkus();
      });
  }

  getServiceSKUs(): void {
    const payload = {
      vendorID: this.storageService.getCookie('userID'),
    };
    this.catalogMngtService
      .getServices(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response && response?.data) {
          this.services = response?.data;
          this.copyServices = this.services;
        }
        this.getCategoryWiseSkus();
      });
  }

  getCategoryWiseSkus(): void {
    if (this.tags?.length > 0) {
      const specItem = this.tags[0];
      const catID = this?.tags[0]?.category;
      if (this.isService) {
        this.serviceSKUs = [];
        if (this.triggerID == 2) {
          this.copyServiceSKUs = this.copyServices?.filter(
            (item: any) => item.category == catID
          );
        } else {
          this.copyServiceSKUs = this.copyServices;
        }
        for (let i = 0; i < this.copyServiceSKUs?.length; i++) {
          this.serviceSKUs.push({
            id: this.copyServiceSKUs[i].sku,
            text: this.copyServiceSKUs[i].sku,
          });
        }
        if (this.serviceSKUs?.length > 0) {
          this.productForm.patchValue({
            sku: this.serviceSKUs[0]?.id,
          });
          this.getSelectedItem(this.productForm.value?.sku);
        }
      } else {
        this.productSKUs = [];
        if (this.triggerID == 2) {
          this.copyProductSKUs = this.copyProducts?.filter(
            (item: any) => item.category == catID
          );
        } else {
          this.copyProductSKUs = this.copyProducts;
        }
        for (let i = 0; i < this.copyProductSKUs?.length; i++) {
          this.productSKUs.push({
            id: this.copyProductSKUs[i].sku,
            text: this.copyProductSKUs[i].sku,
          });
        }
        if (this.productSKUs?.length > 0) {
          this.productForm.patchValue({
            sku: this.productSKUs[0]?.id,
          });
          this.getSelectedItem(this.productForm.value?.sku);
        }
      }
      if (!this.isCrosssellUpsellAdded) {
        const startDate = formatDate(
          moment(specItem?.start_date).toDate(),
          'dd/MM/yyyy',
          'en-US'
        );
        const endDate = formatDate(
          moment(specItem?.end_date).toDate(),
          'dd/MM/yyyy',
          'en-US'
        );
        this.productForm.patchValue({
          sku: specItem?.sku ? specItem?.sku : '',
          startDate: startDate,
          startTime: specItem?.start_time,
          endDate: endDate,
          endTime: specItem?.end_time,
        });
        this.setStartDateOption(moment().toDate(), [
          moment(startDate, 'DD/MM/YYYY').toDate(),
        ]);
        this.setEndDateOption(moment(startDate, 'DD/MM/YYYY').toDate(), [
          moment(endDate, 'DD/MM/YYYY').toDate(),
        ]);
      } else {
        this.reset();
      }
    } else {
      if (this.isService) {
        this.serviceSKUs = [];
        this.copyServiceSKUs = this.copyServices;
        for (let i = 0; i < this.copyServiceSKUs?.length; i++) {
          this.serviceSKUs.push({
            id: this.copyServiceSKUs[i].sku,
            text: this.copyServiceSKUs[i].sku,
          });
        }
        if (this.serviceSKUs?.length > 0) {
          this.productForm.patchValue({
            sku: this.serviceSKUs[0]?.id,
          });
          this.getSelectedItem(this.productForm.value?.sku);
        }
      } else {
        this.productSKUs = [];
        this.copyProductSKUs = this.copyProducts;
        for (let i = 0; i < this.copyProductSKUs?.length; i++) {
          this.productSKUs.push({
            id: this.copyProductSKUs[i].sku,
            text: this.copyProductSKUs[i].sku,
          });
        }
        if (this.productSKUs?.length > 0) {
          this.productForm.patchValue({
            sku: this.productSKUs[0]?.id,
          });
          this.getSelectedItem(this.productForm.value?.sku);
        }
      }
    }
  }

  getSelectedItem(data: any): void {
    let specificItem;
    if (this.isService) {
      specificItem = this.services?.filter((item: any) => item.sku == data);
    } else {
      specificItem = this.products?.filter((item: any) => item.sku == data);
    }
    this.selectedItem = specificItem?.[0];
  }

  reset(): void {
    this.productForm.reset();
    this.setStartDateOption(moment().toDate(), false);
    this.setEndDateOption(moment().toDate(), false);
  }

  save(): void {
    if (this.productForm.invalid) {
      this.productForm.markAllAsTouched();
      return;
    } else {
      const { startDate, endDate } = this.productForm.getRawValue();
      const formData = {
        ...this.productForm.value,
        startDate: formatDate(
          moment(startDate, 'DD-MM-YYYY').toDate(),
          'yyyy-MM-dd',
          'en-US'
        ),

        endDate: formatDate(
          moment(endDate, 'DD-MM-YYYY').toDate(),
          'yyyy-MM-dd',
          'en-US'
        ),
      };
      const payload = {
        formData,
        itemData: this.selectedItem,
      };
      this.close(payload);
    }
  }

  get f() {
    return this.productForm.controls;
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  startTimeChange() {
    const startDate = this.productForm.controls.startDate.value;
    const endDate = this.productForm.controls.endDate.value;
    const startTime = this.productForm.controls.startTime.value;
    const endTime = this.productForm.controls.endTime.value;

    const isSameDate = moment(startDate, 'DD/MM/YYYY').isSame(
      moment(endDate, 'DD/MM/YYYY').toDate(),
      'day'
    );

    if (startDate && endDate && isSameDate && startTime >= endTime) {
      this.endMinTime = moment(startTime, 'HH:mm')
        .add(1, 'hours')
        .format('HH:mm');

      this.productForm.controls.endTime.setValue(this.endMinTime);
    }
  }

  private setStartMinTime(date: string) {
    const isToday = moment(date, 'DD/MM/YYYY').isSame(moment(), 'day');
    if (isToday) {
      this.startMinTime = moment().format('HH:mm');
    } else {
      this.startMinTime = '00:00';
    }
    this.productForm.controls.startTime.setValue(this.startMinTime);
    this.startTimeChange();
  }

  private setEndMinTime(date: string) {
    const isToday = moment(date, 'DD/MM/YYYY').isSame(moment(), 'day');
    if (isToday) {
      this.endMinTime = moment().add(1, 'hours').format('HH:mm');
    } else {
      this.endMinTime = '00:00';
    }
    this.productForm.controls.endTime.setValue(this.endMinTime);
    this.startTimeChange();
  }

  changeDate(id: number): void {
    if (id == 1) {
      this.fromDate = this.productForm?.value?.startDate;

      let minDate = this.fromDate
        ? moment(this.fromDate, 'DD/MM/YYYY').toDate()
        : moment().toDate();

      let isSameOrAfter = moment(this.fromDate, 'DD/MM/YYYY').isAfter(
        moment(this.productForm?.value?.endDate, 'DD/MM/YYYY')
      );

      if (isSameOrAfter) {
        this.productForm.controls['endDate'].reset();
        this.setEndDateOption(minDate);
      } else {
        this.setEndDateOption(
          minDate,
          this.productForm?.value?.endDate
            ? [moment(this.productForm?.value?.endDate, 'DD/MM/YYYY').toDate()]
            : false
        );
      }
      this.setStartMinTime(this.fromDate);
    } else {
      this.todate = this.productForm?.value?.endDate;

      this.setEndMinTime(this.todate);
    }
  }

  private setEndDateOption(
    minDate: Date,
    selectedDates?: Array<string | number | Date> | false,
    dateFormat?: string,
    position?: AirDatepickerPosition
  ) {
    let options: AirDatepickerOptions = {
      ...this.endDateOptions,
      minDate: minDate,
      dateFormat: dateFormat || this.endDateOptions.dateFormat,
      position: position || this.endDateOptions.position,
    };

    if (selectedDates && selectedDates.length) {
      options['selectedDates'] = selectedDates;
    } else {
      options['selectedDates'] = false;
    }

    this.endDateOptions = {
      ...options,
    };
  }

  private setStartDateOption(
    minDate: Date,
    selectedDates?: Array<string | number | Date> | false,
    dateFormat?: string,
    position?: AirDatepickerPosition
  ) {
    let options: AirDatepickerOptions = {
      ...this.startDateOptions,
      minDate: minDate,
      dateFormat: dateFormat || this.endDateOptions.dateFormat,
      position: position || this.endDateOptions.position,
    };

    if (selectedDates && selectedDates.length) {
      options['selectedDates'] = selectedDates;
    } else {
      options['selectedDates'] = false;
    }

    this.startDateOptions = {
      ...options,
    };
  }
}
