import { DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-verify-email-success-modal',
  templateUrl: './verify-email-success-modal.component.html',
})
export class VerifyEmailSuccessModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();

  constructor(public dialogRef: DialogRef<any>) {}

  ngOnInit(): void {}

  closeMdl(data?: any): void {
    this.dialogRef.close(data);
  }
}
