import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payout-success-modal',
  templateUrl: './payout-success-modal.component.html',
})
export class PayoutSuccessModalComponent implements OnInit {
  @Input() referenceNumber: any;

  constructor() {}

  ngOnInit(): void {}

  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }
}
