import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { IUserInfo } from '@app/core/model/common-model';
import { CredentialService } from '@app/core/services/credential.service';
import { ValidationService } from '@app/core/services/validation.service';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
  selector: 'app-edit-variant-option-modal',
  templateUrl: './edit-variant-option-modal.component.html',
})
export class EditVariantOptionModalComponent implements OnInit {
  editOptionValueData: any;
  formData = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    price: new UntypedFormControl(''),
    sale_price: new UntypedFormControl('', [ValidationService.required]),
    sku: new UntypedFormControl('', [ValidationService.required]),
    available_stock: new UntypedFormControl('', [
      ValidationService.required,
      Validators.min(1),
    ]),
    barcode: new UntypedFormControl(''),
  });
  formName: string;
  taxPercentage: number = 0;
  userDetails: IUserInfo;
  isSubmitted = false;

  constructor(
    public dialogRef: DialogRef<any>,
    public crendentialService: CredentialService,
    @Inject(DIALOG_DATA)
    public data: {
      editOptionValueData: any;
      isBackorderAllowed: boolean;
      taxPercentage: number;
    },
    public generalService: GeneralService
  ) {
    if (data?.editOptionValueData) {
      this.editOptionValueData = data.editOptionValueData;
    }
    if (data?.taxPercentage) {
      this.taxPercentage = data.taxPercentage;
    }
  }

  get f() {
    return this.formData.controls;
  }

  ngOnInit(): void {
    if (this.data.isBackorderAllowed) {
      this.formData.get('available_stock').clearValidators();
      this.formData.get('available_stock').updateValueAndValidity();
    }
    this.userDetails = this.crendentialService.userDetails;
    if (this.editOptionValueData) {
      this.changeOptionValueData();
      this.formName = this.formData.controls['name'].value;
    }
  }

  changeOptionValueData() {
    if (this.editOptionValueData) {
      this.formData.patchValue({
        id: this.editOptionValueData?.id,
        name: this.editOptionValueData?.name,
        price: this.editOptionValueData?.price,
        sale_price: this.editOptionValueData?.sale_price,
        available_stock: this.editOptionValueData?.available_stock,
        sku: this.editOptionValueData?.sku,
        barcode: this.editOptionValueData?.barcode,
      });
    }
  }

  calculateTotalAmount(salePrice: any): number {
    const taxRate = this.taxPercentage;
    const totalPrice = Number(salePrice) ? Number(salePrice) : 0;
    const taxAmount = (Number(totalPrice) * Number(taxRate)) / 100;
    const totalAmount = Number(totalPrice) + Number(taxAmount);
    return totalAmount > 0 ? totalAmount : 0.0;
  }

  checkValidPrice() {
    const regularPrice = Number(this.formData.controls['price'].value);
    const salePriceError = this.formData.controls['sale_price']?.errors;
    const salePrice = Number(this.formData.controls['sale_price'].value);
    if (regularPrice > 0 && regularPrice < salePrice) {
      this.formData.controls['sale_price']?.setErrors({
        ...salePriceError,
        salePriceInvalid: true,
      });
    } else {
      if (salePriceError) {
        delete salePriceError['salePriceInvalid'];
      }
      this.formData.controls['sale_price']?.setErrors({
        ...salePriceError,
      });
      this.formData.controls['sale_price'].updateValueAndValidity();
    }
  }

  save(): void {
    this.isSubmitted = true;
    if (this.formData.invalid) {
      return;
    } else {
      this.close(this.formData.getRawValue());
    }
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  reset(): void {
    this.formData.reset();
  }
}
