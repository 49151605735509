import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public getCookie(key: string): any {
    return localStorage.getItem(key + 'Vendor');
  }

  public setCookie(key: string, value: any): any {
    return localStorage.setItem(key + 'Vendor', value);
  }

  public removeItem(key: any): any {
    return localStorage.removeItem(key + 'Vendor');
  }

  public clearCookie(): any {
    const isRemembered = this.getCookie('isRemembered');
    const email = this.getCookie('uname');
    Object.entries(localStorage).forEach(([key, val]) => {
      if (key.slice(-6) === 'Vendor') {
        if (key != 'isRememberedVendor') delete localStorage[key];
      }
    });
    if (isRemembered) {
      this.setCookie('uname', email);
    }
    this.deleteCookieExpiration('AccessToken');
    return;
  }

  setCookieExpiration(key: string, value: string, expDays: number = 1): void {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + expDays);
    document.cookie =
      encodeURIComponent(key + 'Vendor') +
      '=' +
      encodeURIComponent(value) +
      (!expDays ? '' : '; expires=' + exdate.toUTCString()) +
      ';path=/';
  }

  getCookieExpiration(name: string): string {
    const match = document.cookie.match(
      new RegExp('(^| )' + (name + 'Vendor') + '=([^;]+)')
    );
    if (match) return match[2];
    else return '';
  }

  deleteCookieExpiration(name: string): void {
    document.cookie =
      name + 'Vendor' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  getRoleAccessDetails(key: any): any {
    const roleData = this.getCookie('role');
    const role = roleData ? JSON.parse(roleData) : {};
    return role?.[key] ? role?.[key] : '';
  }
}
