import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-verified-confirmation-modal',
  templateUrl: './mobile-verified-confirmation-modal.component.html',
})
export class MobileVerifiedConfirmationModalComponent implements OnInit {
  constructor(public dialogRef: DialogRef<any>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
