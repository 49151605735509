import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Select2OptionData } from 'ng-select2';
import { takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SupportService } from 'src/app/core/services/support.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-add-admin-support-ticket-modal',
  templateUrl: './add-admin-support-ticket-modal.component.html',
})
export class AddAdminSupportTicketModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  page: any;
  id: any;

  modules: Array<Select2OptionData> = [
    {
      id: 'products',
      text: 'Product',
    },
    {
      id: 'services',
      text: 'Service',
    },
    {
      id: 'coupons',
      text: 'Coupon',
    },
  ];
  moduleFields: Array<Select2OptionData> = [];
  fields: any = [];

  ticketData = new UntypedFormGroup({
    subject: new UntypedFormControl('', [Validators.required]),
    module: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      page: any;
      id: any;
    },
    private supportService: SupportService,
    private storageService: StorageService,
    private generalService: GeneralService
  ) {
    super();

    if (data?.id) {
      this.id = data.id;
    }

    if (data.page) {
      this.page = data.page;
    }
  }

  ngOnInit(): void {}

  getTableFields(data: any): void {
    const payload = {
      module: data,
    };
    this.supportService
      .getTableFields(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: any) => {
          if (res) {
            this.moduleFields = res?.data;
            this.fields = [];
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  selectFields(event: any): void {
    this.fields?.push(event);
    const unique = this.fields?.filter(
      (obj: any, index: any) =>
        this.fields?.findIndex((item: any) => item == obj) == index
    );
    this.fields = unique;
  }

  removeField(index: any): void {
    this.fields?.splice(index, 1);
  }

  save(): void {
    if (this.ticketData.invalid) {
      this.ticketData.markAllAsTouched();
    } else if (this.fields?.length == 0) {
      this.generalService.displayWarning('Please select fields');
    } else {
      const payload = {
        subject: this.ticketData.value?.subject,
        description: this.ticketData.value?.description,
        order: this.ticketData.value?.order,
        seller: this.storageService.getCookie('userID'),
        status: 0, // 0 => open, 1=> close
        tickets_by: 1, //0 => customer to seller, 1=> seller to admin,
        module: this.ticketData.value?.module,
        fields_to_edit: this.fields,
        service_id: this.page == 'service' ? this.id : '',
        product_id: this.page == 'product' ? this.id : '',
        coupon_id: this.page == 'coupon' ? this.id : '',
      };
      this.supportService.addTicket(payload).subscribe(
        (res) => {
          this.generalService.displaySuccess(res.message);
          this.close();
        },
        (err) => {
          console.log('err', err);
        }
      );
    }
  }

  get f() {
    return this.ticketData.controls;
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }
}
