<div class="card-box t-card-primary">
  <div class="card-box-header">
    <h4 class="card-title">Variants</h4>
  </div>
  <div class="card-box-body">
    <div class="edit-form-row full-form-row">
      <!--Options Name Config-->
      <div class="form-group">
        <div class="left-title">
          <h6>Option Name</h6>
        </div>
        <div class="right-field">
          <!--Selected-->
          <div class="field-with-action" *ngIf="!isCustomVariantExpand">
            <ng-select2
              *ngIf="optionNameList.length > 0"
              class="select2-control"
              style="width: 100%"
              name=""
              id="optionNameID"
              [data]="optionNameList"
              [placeholder]="'Select'"
              [(ngModel)]="selectedVariant"
              (valueChanged)="changeVariantOptionName($event)"
            >
            </ng-select2>
            <a
              href="javascript: void(0)"
              class="btn field-remove-btn"
              (click)="removeSeletecVariantName()"
            >
              <img src="assets/images/trash-icon.svg" alt="trash" />
            </a>
          </div>
          <!--Custom-->
          <div class="field-with-action" *ngIf="isCustomVariantExpand">
            <div class="tags-control" style="width: 100%">
              <input
                appAlphaNumeric
                [allowSpecialCharacters]="'-'"
                type="text"
                class="form-control"
                [(ngModel)]="customVariantName"
                placeholder="Enter custom variant name"
              />
              <a
                href="javascript: void(0)"
                class="add-tags"
                (click)="addCustomVariantName()"
              >
                <img src="assets/images/plus-icon.svg" alt="plus-icon" />
              </a>
            </div>
            <a
              href="javascript: void(0)"
              class="btn field-remove-btn"
              (click)="removeCustomVariantName()"
            >
              <img src="assets/images/trash-icon.svg" alt="trash" />
            </a>
          </div>
          <div class="mt-4">
            <a
              (click)="isCustomVariantExpand = !isCustomVariantExpand"
              href="javascript: void(0)"
              class="add-link-sm"
            >
              <span class="icon">
                <img
                  src="{{
                    !isCustomVariantExpand
                      ? 'assets/images/plus-icon.svg'
                      : 'assets/images/minus-icon.svg'
                  }}"
                  alt="plus-icon"
                />
              </span>
              Add custom variant
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Variant selected update options-->
<div class="card-box t-card-primary" #aa id="aa">
  <div class="card-box-header" *ngIf="isClickAddVariantOption">
    <h4 class="card-title">Add variant options</h4>
  </div>
  <div class="card-box-body" *ngIf="isClickAddVariantOption">
    <div class="edit-form-row full-form-row">
      <div class="form-group">
        <div class="left-title">
          <h6>Option Name</h6>
        </div>
        <div class="right-field">
          <div class="field-with-action">
            <input
              type="text"
              class="form-control"
              [disabled]="true"
              [(ngModel)]="selectedOption.name"
              placeholder="Select Option Name"
              maxlength="50"
            />
            <a href="javascript: void(0)" class="btn field-remove-btn d-none">
              <img src="assets/images/trash-icon.svg" alt="trash" />
            </a>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="left-title">
          <h6>Option Value</h6>
        </div>
        <div class="right-field">
          <div
            class="field-with-action"
            *ngFor="let o of selectedOption?.values; let ind = index"
          >
            <input
              *ngIf="selectedOption?.id !== '2'"
              type="text"
              class="form-control"
              [(ngModel)]="o.name"
              placeholder="Option value"
              maxlength="50"
            />
            <div *ngIf="selectedOption?.id === '2'" class="color-pick-control">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="o.name"
                placeholder="Option value"
                maxlength="50"
              />
              <span class="color-picker-item">
                <input type="color" id="colorPick" [(ngModel)]="o.color_code" />
              </span>
            </div>
            <a
              href="javascript: void(0)"
              class="btn field-remove-btn"
              (click)="removeOptionValue(ind)"
            >
              <img src="assets/images/trash-icon.svg" alt="trash" />
            </a>
          </div>
          <div class="field-with-action">
            <div class="tags-control" style="width: 100%">
              <input
                *ngIf="selectedOption.id !== '2'"
                type="text"
                class="form-control"
                [(ngModel)]="optionValue"
                placeholder="Add another value"
              />
              <div *ngIf="selectedOption.id === '2'" class="color-pick-control">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="optionValue"
                />
                <span class="color-picker-item" title="Choose color">
                  <input type="color" id="colorPick" [(ngModel)]="colorCode" />
                </span>
              </div>
              <a
                href="javascript: void(0)"
                class="add-tags"
                (click)="addOptionValue()"
              >
                <img src="assets/images/plus-icon.svg" alt="plus-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btnrow mt-5">
      <button
        type="button"
        class="btn t-grey-btn-outline"
        (click)="resetOptionValueForm()"
      >
        <img src="assets/images/reset-icon.svg" alt="reset" /> RESET
      </button>
      <button
        type="button"
        class="btn t-primary-btn"
        (click)="updateOptionValue()"
      >
        <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
      </button>
    </div>
  </div>
</div>

<!--Selected Variant list with options-->
<div class="card-box t-card-primary" *ngIf="finalVariantList.length > 0">
  <div class="card-box-header">
    <h4 class="card-title">Variants</h4>
  </div>
  <div class="card-box-body">
    <div
      class="varient-type-group"
      *ngFor="let v of finalVariantList; let index = index"
    >
      <div class="varient-info">
        <h6>{{ v?.option_name }}</h6>
        <div class="varient-list">
          <ul>
            <li *ngFor="let o of v.option_values">
              {{ o?.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="varient-edit">
        <a
          href="javascript: void(0)"
          class="btn t-grey-btn-outline edit-btn mr-1"
          (click)="editVartiant(index)"
        >
          EDIT
        </a>
        <a
          href="javascript: void(0)"
          class="btn t-danger-btn-outline edit-btn"
          (click)="removeOptionValueVariant(index)"
        >
          <img src="assets/images/trash-icon-red.svg" class="m-0" alt="trash" />
        </a>
      </div>
    </div>
    <div class="add-custom-package">
      <a href="javascript: void(0)" class="add-link-sm" (click)="scroll(aa)">
        <span class="icon"
          ><img src="assets/images/plus-icon.svg" alt="plus-icon"
        /></span>
        Add variant option
      </a>
    </div>
    <div class="select-varient-filter d-none">
      <ul>
        <li>
          <a href="javascript: void(0)">All</a>
        </li>
        <li>
          <a href="javascript: void(0)">None</a>
        </li>
        <li>
          <a href="javascript: void(0)"
            >Size
            <span class="icon"
              ><img
                src="assets/images/caret-bottom-blue.svg"
                alt="caret-bottom" /></span
          ></a>
        </li>
      </ul>
    </div>
    <div class="varient-product-table custom-table">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>Variant</th>
              <th>SKU</th>
              <th>Regular Price</th>
              <th>Sale Price</th>
              <th *ngIf="isDisplayTaxColumn">Tax</th>
              <th *ngIf="isDisplayTaxColumn">Total Price</th>
              <th>Available</th>
              <th>Barcode</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let o of allOptionValues; let ind = index">
              <td>
                <div
                  class="thumb-img"
                  appOpenModal
                  [config]="getAssignVariantImageModalData(o.id, o.img_url)"
                  (onModalClose)="getAssignedImg($event)"
                >
                  <img
                    [src]="
                      o.img_url ? o.img_url : 'assets/images/small-thumb.png'
                    "
                    alt="thumb-img"
                  />
                </div>
              </td>
              <td class="text-truncate">
                {{ o.name }}
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  (input)="emitAllOptionValueData()"
                  [(ngModel)]="o.sku"
                  style="width: 170px"
                  [readOnly]="true"
                />
                <div
                  *ngIf="isSubmitted && !o.sku"
                  class="required-note text-danger mt-2"
                >
                  SKU is required.
                </div>
              </td>
              <td>
                <div class="price-field-control">
                  <div class="position-relative">
                    <span class="unit">AED</span>
                    <input
                      appNumbersOnly
                      type="text"
                      (keypress)="generalService.numberOnly($event)"
                      [(ngModel)]="o.price"
                      class="form-control"
                      (input)="emitAllOptionValueData()"
                      style="width: 110px"
                      [readOnly]="true"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div class="price-field-control">
                  <div class="position-relative">
                    <span class="unit">AED</span>
                    <input
                      appNumbersOnly
                      type="text"
                      [(ngModel)]="o.sale_price"
                      class="form-control"
                      (input)="emitAllOptionValueData()"
                      style="width: 110px"
                      [readOnly]="true"
                    />
                  </div>
                  <div
                    *ngIf="isSubmitted && !o.sale_price"
                    class="required-note text-danger mt-2"
                  >
                    Sale price is required.
                  </div>
                  <div
                    *ngIf="isSubmitted && o.price && o.price < o.sale_price"
                    class="required-note text-danger mt-2"
                  >
                    Sale price must be lower then regular price.
                  </div>
                </div>
              </td>

              <td *ngIf="isDisplayTaxColumn">
                <div class="price-field-control">
                  <span class="unit">AED</span>
                  <input
                    appNumbersOnly
                    type="text"
                    [value]="taxPercentageValue"
                    class="form-control"
                    (input)="emitAllOptionValueData()"
                    style="width: 80px"
                    [readOnly]="true"
                  />
                </div>
              </td>
              <td *ngIf="isDisplayTaxColumn">
                <div class="price-field-control">
                  <span class="unit">AED</span>
                  <input
                    appNumbersOnly
                    [readOnly]="true"
                    type="text"
                    class="form-control"
                    [value]="calculateTotalAmount(o)"
                    style="width: 130px"
                  />
                </div>
              </td>

              <td>
                <input
                  appNumbersOnly
                  [allowDecimals]="false"
                  type="text"
                  class="form-control"
                  (input)="emitAllOptionValueData()"
                  [(ngModel)]="o.available_stock"
                  style="width: 70px"
                  [readOnly]="true"
                />
                <div
                  *ngIf="
                    isSubmitted && !isBackorderAllowed && !o.available_stock
                  "
                  class="required-note text-danger mt-2"
                >
                  Stock is required.
                </div>
              </td>
              <td width="10%">
                <input
                  type="text"
                  class="form-control"
                  (input)="emitAllOptionValueData()"
                  [(ngModel)]="o.barcode"
                  style="width: 100px"
                  isSubmitted
                  [readOnly]="true"
                />
              </td>
              <td>
                <div class="variant-edit-action">
                  <a
                    href="javascript: void(0)"
                    class="btn t-grey-btn-outline edit-btn mr-1"
                    appOpenModal
                    [config]="getVariantOptionModalData(o.id)"
                    (onModalClose)="getUpdatedOptionValueData($event)"
                  >
                    EDIT
                  </a>
                  <a
                    *ngIf="false"
                    href="javascript: void(0)"
                    class="btn t-danger-btn-outline edit-btn"
                    (click)="deleteOptionValue(o.id, ind)"
                  >
                    <img
                      src="assets/images/trash-icon-red.svg"
                      alt="trash"
                      class="m-0"
                    />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!--Modals-->
<!-- <div
  class="modal fade custom-modal edit-variant-modal"
  id="edit-variant"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <app-edit-variant-option-modal
      [editOptionValueData]="editOptionValueData"
      [taxPercentage]="taxPercentage"
      (updatedOptionValueData)="getUpdatedOptionValueData($event)"
    ></app-edit-variant-option-modal>
  </div>
</div>
<div
  class="d-none"
  #openEditModal
  data-toggle="modal"
  data-backdrop="static"
  data-keyboard="false"
  data-target="#edit-variant"
></div> -->

<!--Assign variant image-->
<!-- <div
  class="modal fade custom-modal add-custom-info-modal"
  id="assign-img"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <app-assign-variant-image-modal
      [selectAssignedImgUrl]="selectAssignedImgUrl"
      [optionValueID]="optionValueID"
      (assignedImg)="getAssignedImg($event)"
      [existingImages]="oldImages"
      [selectedProductImages]="newImages"
    ></app-assign-variant-image-modal>
  </div>
</div> -->
