import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { Select2OptionData } from 'ng-select2';
import { takeUntil } from 'rxjs';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-report-template-setting-modal',
  templateUrl: './report-template-setting-modal.component.html',
})
export class ReportTemplateSettingModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  @ViewChild('closeMdl') closeMdl: any;
  @Output() dataEvent = new EventEmitter<any>();
  @Output() modalClose = new EventEmitter();

  searchKey = '';
  selectedTempFields: any = [];
  fildForm!: FormGroup;
  reportTemplatelList: any = [];
  copyReportTemplateList: any = [];
  userId!: string;
  isAllSelected = false;
  reportNameList: Array<Select2OptionData> = [];
  selectFild: any = [];

  constructor(
    private dashboardService: DashboardService,
    private storageService: StorageService,
    private generalService: GeneralService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.userId = this.storageService.getCookie('userID');
    this.prepareForm();
    this.customReportTemplatData();
  }

  prepareForm(): void {
    this.fildForm = new UntypedFormGroup({
      fildType: new UntypedFormControl('', Validators.required),
    });
  }

  selectTempField(event: any): void {
    this.getReportFields();
  }

  customReportTemplatData() {
    this.dashboardService
      .getCustomReportTemplats(this.userId)
      .subscribe((res) => {
        this.reportNameList = res.data.map((el: any) => ({
          text: el.template_name,
          id: el._id,
        }));

        if (this.reportNameList.length) {
          this.fildForm.setValue({
            fildType: this.reportNameList[0].id.toString(),
          });
        }
      });
  }

  getReportFields(): void {
    const vendorId = this.storageService.getCookie('userID');
    const template_id = this.fildForm?.value?.fildType;
    if (vendorId && template_id) {
      this.dashboardService
        .getreportTemplateFields(
          {
            template_id,
          },
          vendorId
        )
        .subscribe((res) => {
          this.reportTemplatelList = res.data;
          this.copyReportTemplateList = this.reportTemplatelList;
        });
    }
  }

  search(): void {
    this.reportTemplatelList = this.copyReportTemplateList.filter((el: any) =>
      el.template_field?.includes(this.searchKey)
    );
  }

  save(): void {
    const vendorID = this.storageService.getCookie('userID');
    const fildType = this.fildForm;
    const payload = {
      template_id: fildType.value.fildType,
      field_ids: this.reportTemplatelList,
    };
    this.dashboardService
      .setReportTemplate(payload, vendorID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response) {
          this.generalService.displaySuccess(response.message);
        }
      });
    this.closeModal();
    const dataToSend = fildType.value.fildType;
    this.dataEvent.emit(dataToSend);
  }

  get f() {
    return this.fildForm.controls;
  }

  isChecked(event: any): void {
    if (this.reportTemplatelList) {
      this.isAllSelected = true;
    }
  }

  removeFild(index: any): void {
    this.reportTemplatelList[index].is_selected = false;
  }

  reset(): void {
    this.reportTemplatelList?.map((item: any) => {
      item.is_selected = false;
    });
    this.isAllSelected = false;
  }

  closeModal(): void {
    this.closeMdl?.nativeElement?.click();
    this.modalClose.emit();
  }
}
