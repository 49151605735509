import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { takeUntil } from 'rxjs';
import { AccountSettingsService } from 'src/app/core/services/account-settings.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { VendorService } from 'src/app/core/services/vendor.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-verify-email-address-modal',
  templateUrl: './verify-email-address-modal.component.html',
})
export class VerifyEmailAddressModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef<HTMLInputElement>>;

  email: any;
  mobile: any;
  mobileJSON: any;
  isShowSMSOTP: any;
  isShowEmailOTP: any;

  otp: any = '';
  counter = 60;
  resendClicked = false;
  encodeMobile: any = '';
  otpForm = new UntypedFormGroup({
    digitOne: new UntypedFormControl('', [Validators.required]),
    digitTwo: new UntypedFormControl('', [Validators.required]),
    digitThree: new UntypedFormControl('', [Validators.required]),
    digitFour: new UntypedFormControl('', [Validators.required]),
  });
  otpArray = ['digitOne', 'digitTwo', 'digitThree', 'digitFour'];

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      email: any;
      mobile: any;
      mobileJSON: any;
      isShowSMSOTP: any;
      isShowEmailOTP: any;
    },

    private vendorService: VendorService,
    public generalService: GeneralService,
    private storageServie: StorageService,
    private accountSettingService: AccountSettingsService
  ) {
    super();
    if (data) {
      this.email = data.email;
      this.mobile = data.mobile;
      this.mobileJSON = data.mobileJSON;
      this.isShowSMSOTP = data.isShowSMSOTP;
      this.isShowEmailOTP = data.isShowEmailOTP;
    }
  }

  ngOnInit(): void {
    this.resendClicked = true;
    this.counter = 60;
    if (this.isShowSMSOTP) {
      this.sendOTP();
      this.encodeMobile = this.mobile?.replace(
        this.mobile?.substring(4, 11),
        '*******'
      );
    }
    if (this.isShowEmailOTP) {
      this.startTimer();
    }
  }

  verify(): void {
    if (this.otpForm.invalid) {
      this.otpForm.markAllAsTouched();
      return;
    } else {
      this.otp =
        this.otpForm.value.digitOne +
        '' +
        this.otpForm.value.digitTwo +
        '' +
        this.otpForm.value.digitThree +
        '' +
        this.otpForm.value.digitFour;
      const payload = {
        email: this.email,
        otp: this.otp,
        mobile_number: this.isShowSMSOTP ? this.mobileJSON : '',
      };
      this.accountSettingService
        .verifyEmail(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res) {
              this.otp = '';

              this.close(true);
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  startTimer() {
    const otpTimer = setInterval(() => {
      if (this.counter > 0) {
        this.counter--;
      } else if (this.counter == 0) {
        this.counter = 60;
        clearInterval(otpTimer);
        this.resendClicked = false;
      } else {
        this.counter = 60;
        clearInterval(otpTimer);
        this.resendClicked = false;
      }
    }, 1000);
  }

  handleInput(ele: number): void {
    if (this.otpForm.controls[this.otpArray[ele - 1]].valid) {
      setTimeout(() => {
        this.otpInputs.get(ele)?.nativeElement.focus();
      });
    }
  }

  resendOTP(): void {
    if (this.resendClicked) {
      return;
    }
    if (!this.email && !this.isShowSMSOTP) {
      this.generalService.displayWarning('Please enter valid email');
      return;
    } else if (!this.mobile && this.isShowSMSOTP) {
      this.generalService.displayWarning('Please enter valid mobile');
      return;
    } else {
      this.resendClicked = true;
      const seller_id = this.storageServie.getCookie('userID');
      this.accountSettingService
        .editEmail({
          email: this.email,
          mobile_number: this.isShowSMSOTP ? this.mobileJSON : '',
          seller_id: seller_id,
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response) {
              this.generalService.displaySuccess(response?.message);
              this.startTimer();
            }
          },
          (error) => {
            this.resendClicked = false;
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  sendOTP(): void {
    if (!this.mobile) {
      this.generalService.displayWarning('Please enter valid mobile');
      return;
    } else {
      const seller_id = this.storageServie.getCookie('userID');
      this.accountSettingService
        .editEmail({
          email: this.email,
          mobile_number: this.isShowSMSOTP ? this.mobileJSON : '',
          seller_id: seller_id,
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response) {
              this.generalService.displaySuccess(response?.message);
              this.counter = 60;
              this.startTimer();
            }
          },
          (error) => {
            this.resendClicked = false;
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  get f() {
    return this.otpForm.controls;
  }
}
